import {
    Button,
    Col,
    Row,
    Form,
    Input,
    Select,
    Checkbox,
    Radio,
    message,
    Upload,
    AutoComplete,
  } from "antd";
  
  import React, { useState, useEffect } from "react";
  import { useHistory, useParams } from "react-router-dom";
  import {
    LoadingOutlined,
    PlusOutlined,
    ArrowLeftOutlined,
  } from "@ant-design/icons";
  import AxiosService from "../../../../utils/Config/ApiService";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import SupportLoader from "../../../../utils/Config/SupportLoader";
  import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
  
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  
  const AdminStaffEditProfile = () => {
    //roles

    const [designation, setDesignation] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    // const [empRolesArr, setempRolesArr] = useState([])
    const [loading, setLoading] = useState(false);
    const [countries, setcountries] = useState([]);
    const [allcountries, setallcountries] = useState([]);
    const [states, setstates] = useState([]);
    const [constStates, setConstStates] = useState([]);
    const [constCities, setConstCities] = useState([]);
    const [constCountries, setconstCountries] = useState([]);
    const [cities, setcities] = useState([]);
    const [staff, setstaff] = useState({});
    const [imageUrl, setImageUrl] = useState();
    const [isloader, setisloader] = useState(true);
    const [subloader, setsubloader] = useState(false);
    const [roleList, setroleList] = useState([]);
    const [empRoles, setempRoles] = useState([]);
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const updatedBy = userData.employeeId;
    const staffId = useParams()?.id;
    const history = useHistory();


  
    const handleGetDesignation = async () => {
      try {
        let res = await AxiosService.post(`${ApiRoutes.STAFF_DESIGNATION.service}${ApiRoutes.STAFF_DESIGNATION.url}`, {
          empRoles: empRoles,
          employeeId: staffId,
        });
  
        if (res.status === 200) {
          setDesignation(res.data.designation);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const verifyRoleCount = () => {
      const empRolesArr = [...empRoles];
      const fifteenthVal = empRolesArr.indexOf(15);
      if (fifteenthVal > -1) {
        empRolesArr.splice(fifteenthVal, 1);
      }
  
      const nineteenthVal = empRolesArr.indexOf(19);
      if (nineteenthVal > -1) {
        empRolesArr.splice(nineteenthVal, 1);
      }
  
      return empRolesArr.length;
    };
  
    const handleEditProfile = async (values) => {
      try {
        if (verifyRoleCount() > 0) {
         
          let data = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
            businessPhone: values?.businessPhone,
            businessPhoneCode: staff?.businessPhoneCode,
            // userName : staff?.userName,
            country:
              typeof values?.countryName === "string"
                ? staff?.country
                : values?.countryName,
            state:
              typeof values?.stateName === "string"
                ? staff?.state
                : values?.stateName,
            city:
              typeof values?.cityName === "string"
                ? staff?.city
                : values?.cityName,
            zip: values?.zip,
            addressLine1: values?.addressLine1,
            updatedBy: updatedBy,
            roleList: empRoles,
            isWrite:staff.isWrite,
            isFeatureManagement:staff.isFeatureManagement
          };
         
            console.log(data)
            let res = await AxiosService.put(`${ApiRoutes.ADMIN_STAFF.service}${ApiRoutes.ADMIN_STAFF.url}/${staffId}`, data);
            if (res.status === 200) {
              setsubloader(false);
              history.goBack();
            }
         
        } else {
          alert("Please select aleast one role");
          setsubloader(false);
        }
      } catch (error) {
        console.log(error);
        setsubloader(false);
      }
    };
    const handleGetCountries = async () => {
      try {
        let res = await AxiosService.get(`${ApiRoutes.COUNTRY.service}${ApiRoutes.COUNTRY.url}`);
        if (res.data.statusCode === 200) {
          setallcountries(res.data.data);
          setcountries(
            res.data.data?.map((ele) => ({
              value: ele?.countryId,
              label: ele?.name,
            }))
          );
          setconstCountries(
            res.data.data?.map((ele) => ({
              value: ele?.countryId,
              label: ele?.name,
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    const [form] = Form.useForm();
    const handleSearch = (value, constStateVariables, setState) => {
      //search fn for select
  
      if (value?.length > 0 && value !== null) {
        const filteredData = constStateVariables.filter((state) =>
          state.label.toLowerCase().includes(value.toLowerCase())
        );
        return setState(filteredData);
      } else {
        return setState(constStateVariables);
      }
    };
  
    const handleGetStates = async (id) => {
      try {
        let res = await AxiosService.get(`${ApiRoutes.STATE.service}${ApiRoutes.STATE.url}/${id}`);
        if (res.data.statusCode === 200) {
          setstates([
            { value: "default", label: "Please select a state", disabled: true },
            ...res.data.data?.map((ele) => ({
              label: ele?.stateName,
              value: ele?.stateId,
            })),
          ]);
          setConstStates([
            { value: "default", label: "Please select a state", disabled: true },
            ...res.data.data?.map((ele) => ({
              label: ele?.stateName,
              value: ele?.stateId,
            })),
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleGetCities = async (id) => {
      try {
        let res = await AxiosService.get(`${ApiRoutes.CITY.service}${ApiRoutes.CITY.url}/${id}`);
  
        if (res.data.data.length > 0) {
          setcities([
            { value: "", label: "Please select a city", disabled: true },
            ...res.data.data?.map((ele) => ({
              value: ele?.cityId,
              label: ele?.cityName,
            })),
          ]);
          setConstCities([
            ...res.data.data?.map((ele) => ({
              value: ele?.cityId,
              label: ele?.cityName,
            })),
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const onCountryChange = (value) => {
      handleSearch(null, constCountries, setcountries);
      setstaff({
        ...staff,
        businessPhoneCode:
          "+" + (allcountries?.find((ele) => ele.countryId === value)).phoneCode,
        country: value,
        state: null,
        city: null,
      });
      form.setFieldsValue({
        businessPhoneCode:
          "+" + (allcountries?.find((ele) => ele.countryId === value)).phoneCode,
        stateName: "Please select a state",
        cityName: "Please select a city",
      });
  

  
      setSelectedCountry(value);
  
      setstates([]);
      handleGetStates(value);
    };
  
    useEffect(() => {
      handleGetCountries();
    }, []);
  
    const onFinish = (values) => {
      handleEditProfile(values);
    };


  const getRoleList = async () =>{
    try{
      let res = await AxiosService.get(`${ApiRoutes.ADMIN_STAFF_ROLELIST.service}${ApiRoutes.ADMIN_STAFF_ROLELIST.url}`);
      if(res.status === 200){
        setroleList(res.data.roleList);
      }
    }catch(error){
      console.log(error)
    }
  }
    const getStaffById = async () => {
      try {
        let res = await AxiosService.get(`${ApiRoutes.ADMIN_STAFF.service}${ApiRoutes.ADMIN_STAFF.url}/${staffId}`);
        if (res.data.statusCode === 200) {
          setstaff(res.data.employeeDetails);          
          setempRoles(res.data.roleList?.map((role) => role.roleId));
          if (res.data.employeeDetails?.country) {
            handleGetStates(res.data.employeeDetails?.country);
          }
          if (res.data.employeeDetails?.state) {
            handleGetCities(res.data.employeeDetails?.state);
          }
  
          setisloader(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      getRoleList();
      getStaffById();

    }, []);
  
 
  
 
    const handleDefaultChecked = (id, text) => {
      return empRoles?.some((roleId) => roleId === id);
    };
  



  
    return (
      <>
        {isloader ? (
          <div className="adminContentSection">
            <SupportLoader />
          </div>
        ) : (
          <div className="adminContentSection">
            <Row gutter={8}>
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                className="editProfileForm"
                style={{ width: "100%" }}
                initialValues={{
                  firstName: staff?.firstName || "",
                  lastName: staff?.lastName || "",
                  email: staff?.email || "",
                  businessPhone: staff?.businessPhone || "",
                  userName: staff?.userName || "",
                  state: staff?.state || "",
                  city: staff?.city || "",
                  country: staff?.country || "",
                  stateName: staff?.stateName || "",
                  cityName: staff?.cityName || "",
                  countryName: staff?.countryName || "",
                  zip: (staff?.zip && staff?.zip !== "null" && staff?.zip) || "",
                  addressLine1: staff?.addressLine1 || "",
                  isAllowDiscount: staff?.isAllowDiscount || "",
                  saleItemPermission: staff?.saleItemPermission || "",
                }}
                onFinish={onFinish}
              >
                <Col span={24}>
                  <div className="profileDetailsRight staffAdminForm bg-white mb-1">
                    <Row gutter={18}>
                      <Col span={12}>
                        <Form.Item
                          label="First Name"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Last Name"
                          name="lastName"
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: "Please input your last name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="User Name" name="userName" rules={[
                            {
                              required: true,
                              message: "Please input your user name",
                            },
                          ]}>
                          <Input
                            disabled
                            onChange={(value) => {
                              setstaff({
                                ...staff,
                                userName: value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Email" name="email" 
                            rules={[
                              {
                                required: true,
                                message: "Please input your email",
                              },
                            ]}>
                          <Input
                            onChange={(value) => {
                              setstaff({
                                ...staff,
                                email: value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Country" name="countryName" className="selectDropdown" rules={[
                            {
                              required: true,
                              message: "Please select country name",
                            },
                          ]}>
                          <Select
                            style={{ width: "100%" }}
                            options={countries}
                            onChange={onCountryChange}
                            onSearch={(value) => {
                              handleSearch(value, constCountries, setcountries);
                            }}
                            showSearch={true}
                            filterOption={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Mobile Phone" name="businessPhone" rules={[
                            {
                              required: true,
                              message: "Please input your mobile number",
                            },
                          ]} className="prefixInput">
                          <Input
                                addonBefore={
                                  <Form.Item
                                    noStyle
                                    name="prefix"
                                    initialValue={staff?.businessPhoneCode}
                                  >
                                     <Input
                                        style={{ width: 80 }}
                                        onChange={(e) => {
                                          setstaff({
                                            ...staff,
                                            businessPhoneCode: e.target.value,
                                          });
                                        }}
                                  />
                                  </Form.Item>
                                }
                            onChange={(value) => {
                              setstaff({
                                ...staff,
                                businessPhone: value,
                              });
                            }}
                         
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="State" name="stateName" className="selectDropdown" rules={[
                            {
                              required: true,
                              message: "Please select your state name",
                            },
                          ]}>
                          <Select
                            style={{ width: "100%" }}
                            selected={selectedCountry === null}
                            // labelInValue
                            onSearch={(value) => {
                              handleSearch(value, constStates, setstates);
                            }}
                            showSearch={true}
                            filterOption={false}
                            options={states}
                            onChange={(value) => {
                              handleSearch(null, constStates, setstates); //to reset the search option to inital state
                              setstaff({
                                ...staff,
                                state: value,
                              });
                              handleGetCities(value);
  
                              setstaff({
                                //reset city as state changes
                                ...staff,
                                city: null,
                              });
                              form.setFieldsValue({
                                cityName: "Please select a city",
                              });
                            }}
                            value={selectedCountry ? "" : staff.state || ""}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="City" name="cityName" className="selectDropdown" rules={[
                            {
                              required: true,
                              message: "Please select your city name",
                            },
                          ]}>
                          <Select
                            style={{ width: "100%" }}
                            options={cities}
                            onSearch={(value) => {
                              handleSearch(value, constCities, setcities);
                            }}
                            showSearch={true}
                            filterOption={false}
                            onChange={(value) => {
                              handleSearch(null, constCities, setcities);
                              setstaff({
                                ...staff,
                                city: value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Street Address" name="addressLine1">
                          <Input
                            onChange={(value) => {
                              setstaff({
                                ...staff,
                                addressLine1: value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Zipcode" name="zip" rules={[
                            {
                              required: true,
                              message: "Please input your zip code",
                            },
                          ]}>
                          <Input
                            onChange={(value) => {
                              setstaff({
                                ...staff,
                                zip: value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={18}>
                      <Col span={24}>
                        <h4 className="m-tb1 fw-600">Roles</h4>
                      </Col>
                    </Row>
                    <Row gutter={18}>
                      <Col span={24} className="custom--checkbox">
                        {roleList.length > 0 ? (
                          roleList?.map((role, i) => {
                            return (
                              <div key={role.roleId}>
                                <Checkbox
                                  name={role?.Description}
                                  // value={role?.roleId}
                                  defaultChecked={handleDefaultChecked(
                                    role?.roleId,
                                    role?.Description
                                  )}
                                  onChange={(e) => {
                                    const roleId = role?.roleId;
                                    if (e.target.checked) {
                                      setempRoles([...empRoles, role?.roleId]);

                                    } else {
                                      setempRoles(
                                        empRoles.filter((id) => id !== roleId)
                                      );
                                    }
                                  }}
                                  key={i}
                                >
                                  {role?.Description}
                                    {
                                      role?.Description === "Merchant admin"?<div className=''>
                                        <Radio.Group onChange={(e)=>{
                                          setstaff({
                                            ...staff,
                                            isWrite:e.target.value
                                          })
                                        }} defaultValue={staff.isWrite===1?1:0}>
                                              <Radio value={0}>Read</Radio>
                                              <Radio value={1}>Write</Radio>
                                            </Radio.Group>
                                      </div>:role?.Description === "Support Admin"?<div>
                                          <Checkbox 
                                           defaultChecked = {staff.isFeatureManagement===1?true:false}
                                          onChange={(e)=>{ 
                                            setstaff({
                                              ...staff,
                                              isFeatureManagement:e.target.checked?1:0
                                            })
                                          }}>
                                            Feature Management
                                          </Checkbox>
                                      </div>:<></>
                                    }
                                </Checkbox>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Col>
  
                      <br />
                      <br />
                    </Row>
                  </div>
                  <div className="text-right mb-1">
                    <Button
                      type="primary"
                      className="mr-3 btn--large"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="warning"
                      htmlType="submit"
                      className="btn--large"
                      onClick={() => setsubloader(true)}
                    >
                      {subloader ? <SupportLoader /> : "Update"}
                    </Button>
                  </div>
                </Col>
              </Form>
            </Row>
          </div>
        )}
      </>
    );
  };
  export default AdminStaffEditProfile;
  