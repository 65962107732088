import { EnvironmentOutlined, MailOutlined, PhoneOutlined, ShopOutlined, UserOutlined, 
LinkOutlined, PrinterOutlined, FontSizeOutlined, PercentageOutlined, CalendarOutlined, 
CreditCardOutlined, IdcardOutlined, NodeExpandOutlined, PicLeftOutlined, AuditOutlined, 
MoneyCollectOutlined, FundViewOutlined, BankOutlined, DollarOutlined, VerifiedOutlined, 
ReconciliationOutlined, UserSwitchOutlined} from '@ant-design/icons';
import { Button, Col, Row, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';
import AxiosService from '../../../../utils/Config/ApiService';

const ViewMerchant = () => {

    const history = useHistory();
    const params = useParams();
  
    const [messageApi, contextHolder] = message.useMessage();
  
    const [merchantDetail, setMerchantDetail] = useState(null)
  
    const getMerchantDetails = async () => {
      try{
        const response = await AxiosService.get(`${ApiRoutes.MERCHANT_DETAIL.service}${ApiRoutes.MERCHANT_DETAIL.url}${params.id}`);
        if(response.status === 200){
          setMerchantDetail(response.data.merchantDetails)
        }
      }catch(error){
        console.log(error)
        messageApi.open({
          type: "error",
          content: error.response.data.error ?? error.response.data.message
        })
      }
    }
  
    useEffect(()=>{
      getMerchantDetails();
    },[])
  
    return (
      <>
        {contextHolder}
        <div className="text-right mb-1">
          <Button
            type="warning"
            className="btn--large"
            onClick={()=>history.goBack()}
          >
            Back
          </Button>
        </div>
        <Row gutter={8} className="mb-05">
          <Col span={24}>
            <div className="profileDetailsRight bg-white">  
              <Row gutter={8}>
                <Col span={16}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <UserSwitchOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Agent Name</p>
                      <p>{merchantDetail?.agentName ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                    <h4 className="mb-1">MERCHANT INFORMATION</h4>
                </Col>
                <Col span={16}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <ShopOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Legal Name Of Business</p>
                      <p>{merchantDetail?.legalName ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <ShopOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Doing Business As (DBA)</p>
                      <p>{merchantDetail?.dba ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <PhoneOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">
                        Best Phone Number to Reach Applicant
                      </p>
                      <p>
                        {merchantDetail?.contactNumber ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <MailOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Email Address</p>
                      <p>{merchantDetail?.businessEmail ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <LinkOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Website</p>
                      <p>{merchantDetail?.businessWebsite ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <PhoneOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Business Phone Number</p>
                      <p>                
                        {merchantDetail?.businessPhoneNumber ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <PrinterOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Business Fax Number</p>
                      <p>
                        {merchantDetail?.businessFaxNumber ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      < EnvironmentOutlined/>
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Business Address</p>
                      <p>
                        {merchantDetail?.businessAddress ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Address Line 2</p>
                      <p>
                        {merchantDetail?.businessAddressLine2 ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">City</p>
                      <p>
                        {merchantDetail?.businessCityName ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">State</p>
                      <p>
                        {merchantDetail?.businessStateName ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Zipcode</p>
                      <p>
                        {merchantDetail?.businessZipCode ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={8} className="mb-05">
          <Col span={24}>
            <div className="profileDetailsRight bg-white">
              <Row gutter={8}>
                <Col span={24}>
                    <h4 className="mb-1">OWNER INFORMATION</h4>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <UserOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">First Name</p>
                      <p>{merchantDetail?.firstName ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <UserOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Last Name</p>
                      <p>{merchantDetail?.lastName ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <FontSizeOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">
                        Title
                      </p>
                      <p>
                        {merchantDetail?.title ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <PercentageOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Ownership</p>
                      <p>{merchantDetail?.ownership ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <CalendarOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Date Of Birth</p>
                      <p>{merchantDetail?.dob ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Home Address</p>
                      <p>                
                        {merchantDetail?.homeAddress ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">State</p>
                      <p>                
                        {merchantDetail?.homeStateName ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">City</p>
                      <p>                
                        {merchantDetail?.homeCityName ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Zipcode</p>
                      <p>                
                        {merchantDetail?.homeZipCode ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <PhoneOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Home Phone #</p>
                      <p>
                        {merchantDetail?.homePhone ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <CreditCardOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Social Security #</p>
                      <p>
                        {merchantDetail?.ssn ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <IdcardOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Driver License #</p>
                      <p>
                        {merchantDetail?.dlno ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Driver License State</p>
                      <p>
                        {merchantDetail?.dlState ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <CalendarOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Driver License Exp Date</p>
                      <p>
                        {merchantDetail?.dlExpiryDate ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <MailOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Email</p>
                      <p>
                        {merchantDetail?.ownerEmail ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Personal Residence</p>
                      <p>
                        {merchantDetail?.personalResidence ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <NodeExpandOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Years In Residence</p>
                      <p>
                        {merchantDetail?.yearsInResidence ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <NodeExpandOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Months In Residence</p>
                      <p>
                        {merchantDetail?.monthsInResidence ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={8} className="mb-05">
          <Col span={24}>
            <div className="profileDetailsRight bg-white">
              <Row gutter={8}>
                <Col span={24}>
                    <h4 className="mb-1">BUSINESS INFORMATION</h4>
                </Col>
                <Col span={16}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <PicLeftOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Description Of Company</p>
                      <p>{merchantDetail?.descriptionOfCompany ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <AuditOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Type Of Ownership</p>
                      <p>{merchantDetail?.typeOfOwnership ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={16}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <MoneyCollectOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">
                        Federal Tax Id Number Of Company
                      </p>
                      <p>
                        {merchantDetail?.federalTaxId ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <FundViewOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Wants American Express?</p>
                      <p>{merchantDetail?.acceptanceOfAmericanExpress === 1 ? "Yes" : "No"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={16}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <BankOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Name Of The Bank You Want Your Funds Deposited Into</p>
                      <p>{merchantDetail?.bankName ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <BankOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Routing Number</p>
                      <p>                
                        {merchantDetail?.routingNumber ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={16}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <BankOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Account Number</p>
                      <p>                
                        {merchantDetail?.accountNumber ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <DollarOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Estimated Total Monthly Bank Card Sales</p>
                      <p>                
                        {merchantDetail?.totalMonthlyBankcardSales ? "$" + merchantDetail?.totalMonthlyBankcardSales : "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <DollarOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Estimated Average Individual Sale amount</p>
                      <p>
                      {merchantDetail?.averageIndividualSaleAmount ? "$" + merchantDetail?.averageIndividualSaleAmount : "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={16}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <ReconciliationOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Needed Equipment Name & Model Number</p>
                      <p>
                      {merchantDetail?.equipmentDetails ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <DollarOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Highest Sale Amount</p>
                      <p>
                      {merchantDetail?.highestSaleAmount ? "$" + merchantDetail?.highestSaleAmount : "-"}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>      
      </>
    )
}

export default ViewMerchant