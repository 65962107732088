import React from "react";
import { Select } from "antd";
import { messages } from "../../../utils/Common/Messages";
import { getUserData } from "../../../utils/Helpers";
const { Option } = Select;

const Paymenttype = (props) => {
  const lan = getUserData().language ?? "EN";
  const tip = getUserData().tip ?? false;
  const creditCardFee = getUserData().creditCardFee ?? false;
  const webLite = getUserData()?.webLite ?? false;
  const appLite = getUserData()?.appLite ?? false;
  const { setPayment, currencySign } = props;
  const selectOnChange = (value) => {
    setPayment(value);
  };
  return (
    <>
      <div className="mb-05">
        <label title={messages.selectPaymentType[lan]}>
          {messages.selectPaymentType[lan]}
        </label>
      </div>
      <Select
        placeholder={messages.selectPaymentType[lan]}
        style={{ width: "100%" }}
        onChange={selectOnChange}
        defaultValue="all"
      >
        <Option value="all">{messages.all[lan]}</Option>
        <Option value="cash">{messages.cash[lan]}</Option>
        <Option value="card">{messages.card[lan]}</Option>
        {currencySign === "$" ? (
          <Option value="ebt">{messages.EBT[lan]}</Option>
        ) : (
          ""
        )}
        <Option value="custom_payment">{messages.customPayment[lan]}</Option>
        {tip ? <Option value="tip">{messages.tip[lan]}</Option> : ""}
        <Option value="other">{messages.other[lan]}</Option>
        {webLite != true && appLite != true ? (
          <Option value="customerInvoice">
            {messages.customerInvoice[lan]}
          </Option>
        ) : (
          ""
        )}
        <Option value="tax">{messages.tax[lan]}</Option>
        {creditCardFee ? <Option value="creditCardFee">{messages.CreditCardFee[lan]}</Option> : ""}
      </Select>
    </>
  );
};
export default Paymenttype;
