import React from "react";
import { Select } from "antd";
import { messages } from "../../../utils/Common/Messages";
import { getUserData } from "../../../utils/Helpers";
const { Option } = Select;

const Employee = (props) => {
  console.log('Employee js',props)
 // props.setEmpSelected(11289)
  const lan = getUserData().language ?? "EN";
  if(props.employeeId!=''){
    props.setEmpSelected(props.employeeId)
  }
  const selectOnChange = (value) => {
    console.log(value)
    props.filterdata("", "", "", "", "",value?value:"");
    props.setEmpSelected(value)
  };
  return (
    <Select
      placeholder={messages.employeeName[lan]}
      allowClear
      showSearch
      showArrow="true"
      className="locationSelect"
      dropdownClassName="locationDropdown"
      style={{ width: 200 }}
      optionFilterProp="children"
      onChange={selectOnChange}
      maxTagCount={2}
      value={props.empSelected}
    >
      {props.employeeList &&
        props.employeeList.map((e, key) => (
          <Option value={e.employeeId} key={key}>
            {e.name}
          </Option>
        ))}
    </Select>
  );
};
export default Employee;
