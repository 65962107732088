import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, message } from 'antd'
import { useHistory } from 'react-router-dom';
import AxiosService from "../../../../utils/Config/ApiService";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import moment from "moment";
import SupportLoader from '../../../../utils/Config/SupportLoader';

const AddCampaign = () => {

  const history = useHistory();

  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [search, setSearch] = useState("")

  let [distributors, setDistributors] = useState([]);
  const [statusOptions, setStatusOptions] = useState([  
    {      
      value: 1,
      label: "Active",
    },  
    {      
      value: 0,
      label: "Inactive",
    },
  ])

  const handleFinish = async (values) => {
    values['startDate'] = values['startDate'].format('YYYY-MM-DD')
    values['endDate'] = values['endDate'].format('YYYY-MM-DD')
    console.log("CAMPAIGN", values)
    setLoader(true)
    try {
      let res = await AxiosService.post(`${ApiRoutes.ADD_CAMPAIGN.service}${ApiRoutes.ADD_CAMPAIGN.url}`, values);
      if(res.status === 201){
        setLoader(false)
        history.goBack();
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
      messageApi.open({
        type: "success",
        content: error.response.data.message ?? error.response.data.error
      })
    }
  }

  const handleGetDistributors = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.DISTRIBUTOR_MENU.service}${ApiRoutes.DISTRIBUTOR_MENU.url}`);
      if (res.data.data.length > 0) {
        setDistributors([
          ...res.data.data?.map((ele) => ({
            value: ele?.distributorId,
            label: ele?.distributorName,
            status: ele?.status
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterDistributors = (key) => {
    let data = distributors?.filter(e => e?.label?.toLowerCase()?.includes(key?.toLowerCase()))
    return data
  }

  useEffect(()=>{
    handleGetDistributors()
  },[])

  return (
    <>
    {contextHolder}
    <div className='bg-white' style={{ padding: "20px" }}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        className="editProfileForm"
        style={{ width: "100%" }}
        initialValues={{...form, 
          status: 1,
          startDate: moment(new Date())
        }}
        onFinish={handleFinish}
      >
        <Row gutter={8} >
            <Col span={12}>
              <Form.Item
                label="Campaign Name"
                name="campaignName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Campaign Name",
                  },
                ]}
              >
                <Input placeholder="Campaign Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Distributor Name"
                name="distributorId"
                rules={[
                  {
                    required: true,
                    message: "Select Distributor",
                  },
                ]}
              >
                <Select
                  placeholder="Distributor"
                  onSearch={(value) => setSearch(value)}
                  filterOption={false}
                  showSearch={true}
                  listHeight={150}
                >
                  {
                    filterDistributors(search).map((e,i) => <Select.Option key={i} value={e.value} 
                      disabled={e.status === 1 ? false : true}>{e.label}</Select.Option>)
                  }
                </Select>  
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[
                  {
                    required: true,
                    message: "Select Start Date",
                  },
                ]}
              >
                <DatePicker
                  className='ant-input'
                  placeholder="Start Date"
                  style={{ display: "flex"}}
                  disabledDate={(value) => value < moment(new Date())}
                  clearIcon={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[
                  {
                    required: true,
                    message: "Select End Date",
                  },
                ]}
              >
                <DatePicker
                  className='ant-input'
                  placeholder="End Date"
                  disabledDate={(value) => value < form.getFieldValue("startDate")}
                  clearIcon={false}
                  style={{ display: "flex"}}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Status"
                name="status"
              >
                <Select
                  placeholder="Status"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                  showSearch={true}
                  options={statusOptions}
                />
              </Form.Item>
            </Col>
        </Row>
        <div className="text-right mb-1">
          <Button
            type="primary"
            className="mr-3 btn--large"
            onClick={()=>history.goBack()}
          >
            Back
          </Button>
          <Button
            type="warning"
            htmlType="submit"
            className="btn--large"
          >
            {loader ? <SupportLoader/> : "Submit"}
          </Button>
        </div>
      </Form>        
    </div>
    </>
  )
}

export default AddCampaign