import React, { useEffect } from "react";
import { Input, Modal, Form, message } from "antd";
import { DatePicker, Space } from 'antd';
import { ApiHelper } from "../../../../utils/Helpers";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import { messages } from "../../../../utils/Common/Messages";
import moment from "moment";
const Email = (props) => {
  const {filterData,editData,lan} = props
  console.log(editData,filterData)
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      email:editData.email,
      employeeId:editData.employeeId
    });
  }, [props.editData]);

  return (
    <>
      <Modal
        title={"Send Email Statement"}
        visible={props.isModalVisible}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              let payload = {
                email:values.email,
                employeeId:editData.employeeId,
                startDate:filterData.startDate,
                endDate:filterData.endDate,
                type:filterData.type
              }
              
              const response = await new ApiHelper().FetchFromServer(
                ApiRoutes.PAYROLL_EMAIL_STATEMENT.service,
                ApiRoutes.PAYROLL_EMAIL_STATEMENT.url,
                ApiRoutes.PAYROLL_EMAIL_STATEMENT.method,
                ApiRoutes.PAYROLL_EMAIL_STATEMENT.authenticate,
                payload
              );
              if(response.code===200)
              {
                form.resetFields();
                message.success({
                  content: response.messages[0],
                  duration: 2
                });
                props.handleCancel()
              }
              else {
                message.error({
                  content: response.messages[0],
                  duration: 2
                });
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        okText={messages.ok[lan]}
        cancelText={messages.cancel[lan]}
        onCancel={props.handleCancel}
      >
        <Form
            form={form}
            layout="vertical"
            name="reporting_hours_form"
          >
            <>
              <Form.Item
                name="email"
                label={"Email"}
                rules={[
                  {
                    required: true,
                    message:"Email is required"
                  }
                ]}
              >
                <Input />
              </Form.Item>
              
            </>
          </Form>
      </Modal>
    </>
  );
};
export default Email;