import React from "react";
import { Table, Avatar } from "antd";
import { messages } from "../../../../utils/Common/Messages";
import { getUserData } from "../../../../utils/Helpers";

const NonRevenueItemsummarylist = ({
  dataSource,
  interval,
  dateColums,
  defaultCurrency,
  summaryType,
  lan,
}) => {
  const dualPricing = getUserData()?.dualPricing ?? false;
  let columns = [];
  if (interval === "summary") {
    columns = (dualPricing === "true" || dualPricing === true) ? [
      {
        icon: "name",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 50,
        padding: "8px 0 8px 10px!important",
        className: "first-p-r-0",
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
        render: (name,record) => (
          <>
            <div key={name} style={{ backgroundColor: record.productType === "service" ? "bg-secondary" : "" }}>
              <Avatar className="bg-primary fw-700">{name.charAt(0)}</Avatar>
            </div>
          </>
        ),
      },
      {
        title: messages.itemName[lan],
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 220,
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
        className: (record) =>
          record.productType === "service" ? "bg-secondary" : "",
      },
     
     

      {
        title: messages.category[lan],
        dataIndex: "category",
        key: "category",
        align: "left",
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
      },
      {
        title: 'Quantity ',
        dataIndex: "unitsold",
        key: "unitsold",
        align: "left",
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
      },
      {
        title: messages.salesAmount[lan],
        dataIndex: "amount",
        key: "amount",
        align: "right",
        className: "fw-700",
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
        render: (price) => {
          return <>{defaultCurrency + "" + price}</>;
        },
      },
      {
        title: "Inventory Stock",
        dataIndex: "inventoryStock",
        key: "inventoryStock",
        align: "left",
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
       render: (inventoryStock,record) => {
            return <>{  record.productType === "service" ? '-' : parseInt(inventoryStock)}</>;
          },
      },
      // {
      //   title: "Discount",//tempory renaming to move the ticket as requested by gaurang
      //   dataIndex: "discountAmount",
      //   key: "discountAmount",
      //   align: "right",
      //   className: "fw-700",
      //   render: (price) => {
      //     return <>{price ? (defaultCurrency + "" + price) : ""}</>;
      //   },
      //   // render: (price) => {
      //   //   return <>{defaultxxxxCurrency + "" + Math.abs(Number(price)).toFixed(2)}</>;
      //   // },
      // },
      {
        title: "Dual Pricing Discount",
        dataIndex: "cashDiscountAmount",
        key: "cashDiscountAmount",
        align: "right",
        className: "fw-700",
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
        render: (price) => {
          return <>{price ? (defaultCurrency + "" + price) : ""}</>;
        },
        // render: (price) => {
        //   return <>{defaultCurrency + "" + Math.abs(Number(price)).toFixed(2)}</>;
        // },
      },
      {
        title: "Item Type",
        dataIndex: "productType",
        key: "name",
        fixed: "left",
        width: 50,
        padding: "8px 0 8px 10px!important",
        className: "first-p-r-0",
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
        render: (productType) => (
          <>
            <div key={productType}>
              <Avatar className={productType === 'service' ? 'bg-secondary fw-700' : 'bg-primary fw-700'}>{productType.charAt(0)}</Avatar>
            </div>
          </>
        ),
      },
      //  {
      //   title: "Date of Distribution",
      //   dataIndex: "date",
      //   key: "date",
      //   align: "left",
      // },
      
    ] :
      [
        {
          icon: "name",
          dataIndex: "name",
          key: "name",
          fixed: "left",
          width: 50,
          padding: "8px 0 8px 10px!important",
          className: "first-p-r-0",
          onCell: (record) => {
            return {
              className: record.productType === "service" ? "bg-secondary" : "",
            };
          },
          render: (name) => (
            <>
              <div key={name}>
                <Avatar className="bg-primary fw-700">{name.charAt(0)}</Avatar>
              </div>
            </>
          ),
        },
        {
          title: messages.itemName[lan],
          dataIndex: "name",
          key: "name",
          fixed: "left",
          width: 220,
          onCell: (record) => {
            return {
              className: record.productType === "service" ? "bg-secondary" : "",
            };
          },
        },
      
        {
          title: messages.category[lan],
          dataIndex: "category",
          key: "category",
          align: "left",
          onCell: (record) => {
            return {
              className: record.productType === "service" ? "bg-secondary" : "",
            };
          },
        },
        {
          title: 'Quantity ',
          dataIndex: "unitsold",
          key: "unitsold",
          align: "left",
          onCell: (record) => {
            return {
              className: record.productType === "service" ? "bg-secondary" : "",
            };
          },
        },
        {
          title: messages.salesAmount[lan],
          dataIndex: "amount",
          key: "amount",
          align: "right",
          className: "fw-700",
          onCell: (record) => {
            return {
              className: record.productType === "service" ? "bg-secondary" : "",
            };
          },
          render: (price) => {
            return <>{defaultCurrency + "" + price}</>;
          },
        },
        {
          title: "Inventory Stock",
          dataIndex: "inventoryStock",
          key: "inventoryStock",
          align: "left",
          onCell: (record) => {
            return {
              className: record.productType === "service" ? "bg-secondary" : "",
            };
          },
          render: (inventoryStock,record) => {
            return <>{  record.productType === "service" ? '-' : parseInt(inventoryStock)}</>;
          },
        },
        // {
        //   title: "Discount",//tempory renaming to move the ticket as requested by gaurang,
        //   dataIndex: "discountAmount",
        //   key: "discountAmount",
        //   align: "right",
        //   className: "fw-700",
        //   render: (price) => {
        //     return <>{price ? (defaultCurrency + "" + price) : ""}</>;
        //   },
        //   // render: (price) => {
        //   //   return <>{defaultCurrency + "" + Math.abs(Number(price)).toFixed(2)}</>;
        //   // },
        // },
        {
          title: "Item Type",
          dataIndex: "productType",
          key: "name",
          // fixed: "left",
          // width: 50,
          // padding: "8px 0 8px 10px!important",
          // className: "first-p-r-0",
          onCell: (record) => {
            return {
              className: record.productType === "service" ? "bg-secondary" : "",
            };
          },
          render: (productType) => (
            <>
              {/* <div key={productType}> */}
                {productType}
                {/* <Avatar className={productType === 'service' ? 'bg-secondary fw-700' : 'bg-primary fw-700'}>{productType.charAt(0)}</Avatar> */}
              {/* </div> */}
            </>
          ),
        },
        // {
        //   title: "Date of Distribution",
        //   dataIndex: "date",
        //   key: "date",
        //   align: "left",
        // },
      ];
  } else {
    columns = [
      {
        icon: "name",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 50,
        padding: "8px 0 8px 10px!important",
        className: "first-p-r-0",
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
        render: (name) => (
          <>
            <div key={name}>
              <Avatar className="bg-primary fw-700">{name.charAt(0)}</Avatar>
            </div>
          </>
        ),
      },
      {
        title: "Item name",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 220,
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
      },
    ];
    for (let x in dateColums) {
      columns.push({
        title: dateColums[x],
        dataIndex: dateColums[x],
        key: dateColums[x],
        align: "right",
        width: 100,
        className: "fw-700",
        onCell: (record) => {
          return {
            className: record.productType === "service" ? "bg-secondary" : "",
          };
        },
        render: (price) => {
          return (
            <>
              {summaryType === "amount" ? defaultCurrency + "" + price : price}
            </>
          );
        },
      });
    }
  }
  const data =
    summaryType === "amount" ? dataSource.amountData : dataSource.soldData;
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={interval === "summary" || columns.length < 4 ? "" : { x: 1500 }}
      //scroll={{ x: 1500 }}
      className="itemsummaryTable"
       rowClassName={(record) => 
        record.productType === "service" 
          ? "bg-secondary" 
          : ""
      }
    />
  );
};
export default NonRevenueItemsummarylist;