import React from "react";
import { Table, Avatar } from "antd";
import { messages } from "../../../../utils/Common/Messages";
import { getUserData } from "../../../../utils/Helpers";

const Itemsummarylist = ({
  dataSource,
  interval,
  dateColums,
  defaultCurrency,
  summaryType,
  lan,
}) => {
  const dualPricing = getUserData()?.dualPricing ?? false;
  let columns = [];
  if (interval === "summary") {
    columns = (dualPricing === "true" || dualPricing === true) ? [
      {
        icon: "name",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 50,
        padding: "8px 0 8px 10px!important",
        className: "first-p-r-0",
        render: (name) => (
          <>
            <div key={name}>
              <Avatar className="bg-primary fw-700">{name.charAt(0)}</Avatar>
            </div>
          </>
        ),
      },
      {
        title: messages.itemName[lan],
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 220,
      },
      {
        title: "UPC/PLU",
        dataIndex: "skuupc",
        key: "skuupc",
        align: "left",
      },
      {
        title: messages.category[lan],
        dataIndex: "category",
        key: "category",
        align: "left",
      },
      {
        title: messages.unitsSold[lan],
        dataIndex: "unitsold",
        key: "unitsold",
        align: "right",
      },
      {
        title: messages.salesAmount[lan],
        dataIndex: "amount",
        key: "amount",
        align: "right",
        className: "fw-700",
        render: (price) => {
          return <>{defaultCurrency + "" + price}</>;
        },
      },

      {
        title: "Discount",//tempory renaming to move the ticket as requested by gaurang
        dataIndex: "discountAmount",
        key: "discountAmount",
        align: "right",
        className: "fw-700",
        render: (price) => {
          return <>{price ? (defaultCurrency + "" + price) : ""}</>;
        },
        // render: (price) => {
        //   return <>{defaultCurrency + "" + Math.abs(Number(price)).toFixed(2)}</>;
        // },
      },
      {
        title: "Dual Pricing Discount",
        dataIndex: "cashDiscountAmount",
        key: "cashDiscountAmount",
        align: "right",
        className: "fw-700",
        render: (price) => {
          return <>{price ? (defaultCurrency + "" + price) : ""}</>;
        },
        // render: (price) => {
        //   return <>{defaultCurrency + "" + Math.abs(Number(price)).toFixed(2)}</>;
        // },
      },
    ] :
      [
        {
          icon: "name",
          dataIndex: "name",
          key: "name",
          fixed: "left",
          width: 50,
          padding: "8px 0 8px 10px!important",
          className: "first-p-r-0",
          render: (name) => (
            <>
              <div key={name}>
                <Avatar className="bg-primary fw-700">{name.charAt(0)}</Avatar>
              </div>
            </>
          ),
        },
        {
          title: messages.itemName[lan],
          dataIndex: "name",
          key: "name",
          fixed: "left",
          width: 220,
        },
        {
          title: "UPC/PLU",
          dataIndex: "skuupc",
          key: "skuupc",
          align: "left",
        },
        {
          title: messages.category[lan],
          dataIndex: "category",
          key: "category",
          align: "left",
        },
        {
          title: messages.unitsSold[lan],
          dataIndex: "unitsold",
          key: "unitsold",
          align: "right",
        },
        {
          title: messages.salesAmount[lan],
          dataIndex: "amount",
          key: "amount",
          align: "right",
          className: "fw-700",
          render: (price) => {
            return <>{defaultCurrency + "" + price}</>;
          },
        },

        {
          title: "Discount",//tempory renaming to move the ticket as requested by gaurang,
          dataIndex: "discountAmount",
          key: "discountAmount",
          align: "right",
          className: "fw-700",
          render: (price) => {
            return <>{price ? (defaultCurrency + "" + price) : ""}</>;
          },
          // render: (price) => {
          //   return <>{defaultCurrency + "" + Math.abs(Number(price)).toFixed(2)}</>;
          // },
        }
      ];
  } else {
    columns = [
      {
        icon: "name",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 50,
        padding: "8px 0 8px 10px!important",
        className: "first-p-r-0",
        render: (name) => (
          <>
            <div key={name}>
              <Avatar className="bg-primary fw-700">{name.charAt(0)}</Avatar>
            </div>
          </>
        ),
      },
      {
        title: "Item name",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 220,
      },
    ];
    for (let x in dateColums) {
      columns.push({
        title: dateColums[x],
        dataIndex: dateColums[x],
        key: dateColums[x],
        align: "right",
        width: 100,
        className: "fw-700",
        render: (price) => {
          return (
            <>
              {summaryType === "amount" ? defaultCurrency + "" + price : price}
            </>
          );
        },
      });
    }
  }
  const data =
    summaryType === "amount" ? dataSource.amountData : dataSource.soldData;
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={interval === "summary" || columns.length < 4 ? "" : { x: 1500 }}
      //scroll={{ x: 1500 }}
      className="itemsummaryTable"
    />
  );
};
export default Itemsummarylist;