import { Button, Col, Row, Tabs, Form } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import {
  PhoneOutlined,
  ShopOutlined,
  FlagOutlined,
  EnvironmentOutlined,
  MailOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ContainerOutlined,
  FileTextOutlined,
  PushpinFilled,
  
} from "@ant-design/icons";
import {  Input } from 'antd';
import { Link, useHistory, useParams } from "react-router-dom";
import DisableIcon from "../../../../assets/images/support/ic_outline-disabled-visible.png";
import { Staff } from "./Staff";
import { Receipt } from "./Receipt";
import { StoreNotes } from "./StoreNotes";
import { Terminal } from "./Terminal";
import popupclose from "../../../../assets/images/support/popupclose-icon.svg";
import TextArea from "antd/lib/input/TextArea";
import AxiosService from "../../../../utils/Config/ApiService";
import SupportLoader from "../../../../utils/Config/SupportLoader";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import MerchantDetails from "../merchant/MerchantDetails";
const StoreDetails = () => {
  const [form] = Form.useForm();
  let [addToggle, setAddToggle] = useState(false);
  let [isloader, setisloader] = useState(false);
  const [submitloader, setsubmitloader] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [status, setstatus] = useState(false);
  let [totalCount, setTotalCount] = useState(0);
  let [isLoading, setIsLoading] = useState(true);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [statusKey, setStatusKey] = useState("")
  const history = useHistory();
  const [store, setstore] = useState([]);
  const storeid = useParams()?.id;
  const activeKey = sessionStorage.getItem('activePageKey')?sessionStorage.getItem('activePageKey'):"1"
  const merchantId = useParams()?.merchantId;
  const getmerchantById = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.MERCHANT_STORE.service}${ApiRoutes.MERCHANT_STORE.url}/${storeid}`);
      if (res.data.statusCode === 200) {
        setstore(res.data.data[0]);
        sessionStorage.setItem('storeCouId', res.data.data[0].country )
        sessionStorage.setItem('storeCouName', res.data.data[0].countryName )


        res.data.data[0].active == 1 ? setstatus(true) : setstatus(false);

        setisloader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setisloader(true);
    getmerchantById();
  }, []);

  const handleDisableMerchant = async (values) => {
    try {
      setsubmitloader(true);
      const employeeId = JSON.parse(
        sessionStorage.getItem("userData")
      ).employeeId;
      let res = await AxiosService.put(`${ApiRoutes.STORE_TOGGLE.service}${ApiRoutes.STORE_TOGGLE.url}/${storeid}`,{
          supportAdminId: employeeId,
          reason: values?.reason,
          status: status,
        }
      );
      if (res.status === 200) {
        setsubmitloader(false);
        setAddToggle(false);

        setisloader(true);
        getmerchantById();       
      }
    } catch (error) {
      console.log(error);
      setsubmitloader(false);
    }
  };

  return (
    <>
      <div className="adminContentSection">
        <Row gutter={8}>
          <Col span={24}>
            <div className="displayFlex mt10 mb-05 spaceBetween">
              <Button
                type="warning"
                className="mr-3 btn--large d-flex align-items-center"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowLeftOutlined /> Back
              </Button>
              {store?.active === 1 ? (
                <Button
                  type="primary"
                  className="btn--large"
                  onClick={() => {
                    setAddToggle(true);
                    // setstatus(false);
                    setStatusKey("disable")

                  }}
                >
                  <img
                    src={DisableIcon}
                    width="17px"
                    height="17px"
                    className="mr-1"
                    alt="disicon"
                  />{" "}
                  Disable
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="btn--large"
                  onClick={() => {
                    if(store?.merchantStatus === 1){
                      setAddToggle(true);
                      // setstatus(true);
                      setStatusKey("enable")
                    }else{
                      alert('Enable Merchant to enable Store Admin')
                    }
                  }}
                >
                  <img
                    src={DisableIcon}
                    width="17px"
                    height="17px"
                    className="mr-1"
                    alt="enableicon"
                  />{" "}
                  Enable
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {isloader ? (
          <SupportLoader />
        ) : (
          <Row gutter={8} className="mb-05">
            <Col span={8}>
              <div className="profileDetailsLeft bg-white">
                <div className="shortname">
                  {store?.firstName?.slice(0, 1).toUpperCase()}
                  {store?.lastName?.slice(0, 1).toUpperCase()}
                </div>
                <div className="fullname">
                  <p>
                    {" "}
                    {store?.firstName?.toUpperCase()}{" "}
                    {store?.lastName?.toUpperCase()}
                  </p>
                </div>
              </div>
            </Col>
            <Col span={16}>
              <div className="profileDetailsRight bg-white">
                <Row gutter={8}>
                  <Col span={12}>
                    <div className="displayFlex">
                      <div className="mr-3">
                        <ShopOutlined />
                      </div>
                      <div>
                        <p className="fw-700 mb-0">Business Name</p>
                        <p>{store?.organizationName}</p>
                      </div>
                    </div>
                  </Col>
                  {store?.parentOrganizationId ? (
                    <Col span={12}>
                      <div className="displayFlex">
                        <div className="mr-3">
                          <ShopOutlined />
                        </div>
                        <div>
                          <p className="fw-700 mb-0">Organization Name</p>
                          <p
                            onClick={() => {
                              history.push(
                                `/support/merchant/${store?.parentOrganizationId}`
                              );
                            }}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            {store?.parentOrganizationName}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}

                  <Col span={12}>
                    <div className="displayFlex">
                      <div className="mr-3">
                        <PhoneOutlined />
                      </div>
                      <div>
                        <p className="fw-700 mb-0">Business Phone</p>
                        <p>
                          {store?.businessPhoneCode +
                            "-" +
                            store?.businessPhone}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="displayFlex">
                      <div className="mr-3">
                        <UserOutlined />
                      </div>
                      <div>
                        <p className="fw-700 mb-0">Full Name</p>
                        <p>
                          {" "}
                          {store?.firstName} {store?.lastName}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="displayFlex">
                      <div className="mr-3">
                        <UserOutlined />
                      </div>
                      <div>
                        <p className="fw-700 mb-0">User Name</p>
                        <p>
                          {" "}
                          {store?.userName}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="displayFlex">
                      <div className="mr-3">
                        <MailOutlined />
                      </div>
                      <div>
                        <p className="fw-700 mb-0">Email</p>
                        <p>{store?.email}</p>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="displayFlex">
                      <div className="mr-3">
                        <FlagOutlined />
                      </div>
                      <div>
                        <p className="fw-700 mb-0">
                          Country / State / City Name
                        </p>
                        <p>
                          {store?.countryName ? store?.countryName : "-"}{" "}
                          {" / "}{" "}
                          {store?.stateName !== null ? store?.stateName : "-"}{" "}
                          {" / "}{" "}
                          {store?.cityName !== null ? store?.cityName : "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="displayFlex">
                      <div className="mr-3">
                        <EnvironmentOutlined />
                      </div>
                      <div>
                        <p className="fw-700 mb-0">Address</p>
                        <p>{store?.addressLine1}</p>
                      </div>
                    </div>
                  </Col>
                  {
                    store?.organizationLoginId ?  <Col span={12}>
                    <div className="displayFlex">
                      <div className="mr-3">
                      <PushpinFilled/>
                      </div>
                      <div>
                        <p className="fw-700 mb-0">Organization Pin</p>
                        <p>{store?.organizationLoginId}</p>
                      </div>
                    </div>
                  </Col>
                  : <></>
                  }
                 

                  <Col span={12}>

                  
                    <div className="displayFlex">
                    {store?.resellerName !== null && store?.resellerName ?
                    <>
                      <div className="mr-3">
                        <ShopOutlined />
                      </div>
                      <div>
                         
                        
                            <p className="fw-700 mb-0">Reseller Name</p>
                            <p>{store?.resellerName} </p>
                        
                        
                      </div>
                      </>
                      :<></>}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        <Row gutter={8}>
          <Col span={24}>
            <Tabs defaultActiveKey = {activeKey} className="supportTabs" onChange={()=>sessionStorage.removeItem('activePageKey')}>
              <Tabs.TabPane
                key="1"
                tab={
                  <span className="d-flex align-items-center">
                    <UserOutlined className="mr-1" />
                    Staff
                  </span>
                }
              >
                <Staff
                 
                  storeid={storeid}
                  organizationId = {store?.parentOrganizationId}
                  parentLoader={isloader}
                  settoggle={settoggle}
                  merchantStatus={status}
                  staffManagement={store.staffManagement}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span className="d-flex align-items-center">
                    <FileTextOutlined className="mr-1" />
                    Receipt
                  </span>
                }
                key="2"
              
              >
                <Receipt storeid={storeid}/>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span className="d-flex align-items-center">
                    <ContainerOutlined className="mr-1" />
                    Store Notes
                  </span>
                }
                key="3"
              >
                <StoreNotes
                  storeid={storeid}
                  type={"Store"}
                  parentLoader={isloader}
                  toggle={toggle}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span className="d-flex align-items-center">
                    <ContainerOutlined className="mr-1" />
                    Terminals
                  </span>
                }
                key="4"
              >
                <Terminal storeid={storeid} />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
        {addToggle ? (
          <div>
            <div className="divoverlay">
              <div className="divoverlayBlock">
                <div className="popupheaderblock">
                  <h5> Reason </h5>
                  <img
                    src={popupclose}
                    alt="Close"
                    className="cursorPointer"
                    onClick={() => setAddToggle(false)}
                  />
                </div>
                <div className="container-fluid">
                  <div className="overdivScroll">
                    <div className="row mb40">
                      <Form
                      form={form}
                        name="basic"
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                        style={{
                          maxWidth: 600,
                          margin: "10px 20px",
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        autoComplete="off"
                        className="supportForm"
                        onFinish={handleDisableMerchant}
                      >
                        <Form.Item
                          label="Reason"
                          name="reason"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the reason",
                            },
                            
                          ]}
                        >
                          <Input
                          name="reason"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                          
                            if (inputValue.startsWith(' ')) {
                              form.setFieldsValue({ reason: inputValue.trim() });
                            } else {
                              form.setFieldsValue({ reason: inputValue });
                            }
                          }}

                           placeholder="Enter Reason" />
                        </Form.Item>

                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                          className="text-right"
                        >
                          <div className="displayFlex justifyflexend alignCenter">
                            <Button
                              type="default"
                              onClick={() => {setAddToggle(false)}}
                              className="mr-3 btn--cancel btn--large"
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className=" btn--large"
                              onClick={() => {
                                statusKey === "enable" ? setstatus(true) : setstatus(false);
                              }}
                            >
                              {submitloader ? <SupportLoader /> : "Submit"}
                            </Button>
                          </div>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default StoreDetails;
