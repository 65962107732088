import React, { useEffect, useRef, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Skeleton,
  Table,
  Avatar,
  Input,
  Select,
  message,
  Button,
  Form,
  DatePicker
} from "antd";
import {
  ApiHelper,
  getUserData,
  message as cMessage,
  getToken
} from "../../../../utils/Helpers";
import HorizontalMenu from "../../../layout/Horizontalmenu";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import Emptymsg from "../../../layout/message/Emptymsg";
import { messages } from "../../../../utils/Common/Messages";
import { AppConfig } from "../../../../utils/Config/AppConfig";
import { Link } from "react-router-dom";
import EditProduct from "./editProduct";
import {
  SearchOutlined,
  ShopOutlined,
  DownloadOutlined,
  StarOutlined,
  EditOutlined,
  EyeOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import moment from "moment"
import "./productInventory.less";
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const numberFormat = (walletAmount) => {
  return Number(parseFloat(walletAmount).toFixed(2)).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 2 }
  );
};

const ProductInventory = () => {
  const [form] = Form.useForm();
  const { currencySign, language } = getUserData();
  const userData = getUserData();
  const organizationId = userData
    ? parseInt(userData.organizationTypeId) === 1
      ? userData.parentOrganizationId
      : userData.organizationId
    : 0;
  const lan = language ?? "EN";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [lowStock, setLowStock] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [vendorId, setVendorId] = React.useState("");
  const [locationId, setLocationId] = React.useState("");
  const [data, setData] = React.useState([]);
  const [totalReacord, setTotalReacord] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [vendorData, setVendorData] = useState([]);
  const [storeLoction, setStoreLoction] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subcategoryId, setSubCategoryId] = useState("");
  const [range, setRange] = useState("");
  const [editProductId, setEditProductId] = useState("");
  const [editData, setEditData] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  const stateDate = useRef(false);

  const handleDateChange = (dates) => {
    console.log(dates)
    let initialDate = dates[0].format('YYYY-MM-DD')
    let finalDate = dates[1].format('YYYY-MM-DD')
    console.log("HERE", initialDate, finalDate)
    setStartDate(initialDate)
    setEndDate(finalDate)
  }

  const getVendorList = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VENDOR_LIST.service,
      ApiRoutes.VENDOR_LIST.url,
      ApiRoutes.VENDOR_LIST.method,
      ApiRoutes.VENDOR_LIST.authenticate,
      { parentOrganizationId: organizationId, locationId: id },
      undefined
    );
    if (response.code === 200) {
      setVendorData(response.data.data);
    }
  };
  const getCategory = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PRODUCT_CATEGORY.service,
      ApiRoutes.PRODUCT_CATEGORY.url,
      ApiRoutes.PRODUCT_CATEGORY.method,
      ApiRoutes.PRODUCT_CATEGORY.authenticate,
      { organizationId: id },
      undefined
    );
    if (response.code === 200) {
      setCategory(response.data);
    }
  };
  const getSubCategory = async (e) => {
    setCategoryId(e);
    filter({
      target: {
        name: "category",
        value: e
      }
    });
    if (e) {
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.PRODUCT_SUBCATEGORY.service,
        ApiRoutes.PRODUCT_SUBCATEGORY.url,
        ApiRoutes.PRODUCT_SUBCATEGORY.method,
        ApiRoutes.PRODUCT_SUBCATEGORY.authenticate,
        { organizationId: locationId, categoryId: e },
        undefined
      );
      if (response.code === 200) {
        setSubCategory(response.data);
      }
    } else {
      setSubCategoryId("");
      setSubCategory([]);
    }
  };
  const getFilterData = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GETALLFILTER.service,
      ApiRoutes.GETALLFILTER.url + "?organizationId=" + organizationId,
      ApiRoutes.GETALLFILTER.method,
      ApiRoutes.GETALLFILTER.authenticate,
      undefined
    );
    if (response.code === 200) {
      setStoreLoction(response.data.organizationData);
      let storeId = "";
      if (parseInt(userData.organizationTypeId) === 1) {
        storeId = userData.organizationId;
      } else {
        storeId = response.data.organizationData[0].organizationId;
      }
      getCategory(storeId);
      getVendorList(storeId);
      getProductInventory(
        perPage,
        page,
        search,
        vendorId,
        storeId,
        range,
        lowStock,     
      );

      setLocationId(storeId);
      form.setFieldsValue({
        location: storeId
      });
    } else {
      message.error({
        content: cMessage.reportHourError,
        duration: 2
      });
    }
  };

  useEffect(() => {
    getFilterData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(startDate && endDate){
      getProductInventory(
        perPage,
        1,
        search,
        vendorId,
        locationId,
        range,
        lowStock,
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // getProductInventory(perPage, page, search);
    // eslint-disable-next-line
  }, [perPage, page]);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // search function seperately
  const searchFunction = debounce((searchValue) => {
    setSearch(searchValue);
    getProductInventory(perPage, 1, searchValue, vendorId, locationId, range, lowStock);
  }, 1000);

  const handleSearchChange = (e) => {
    searchFunction(e.target.value);
  };

  const filter = (e) => {
    if (e.target.name === "search") {
      setSearch(e.target.value);
      getProductInventory(
        perPage,
        1,
        e.target.value,
        vendorId,
        locationId,
        range,
        lowStock,
      );
    }
    if (e.target.name === "vendor") {
      setVendorId(e.target.value.join());
      getProductInventory(
        perPage,
        1,
        search,
        e.target.value.join(),
        locationId,
        range,
        lowStock,
      );
    }
    if (e.target.name === "location") {
      setLocationId(e.target.value);
      getCategory(e.target.value);
      getVendorList(e.target.value);
      setSubCategoryId("");
      setCategoryId("");
      getProductInventory(
        perPage,
        1,
        search,
        vendorId,
        e.target.value,
        range,
        lowStock,
        "",
        "",
      );
    }
    if (e.target.name === "range") {
      setRange(e.target.value);
      getProductInventory(
        perPage,
        1,
        search,
        vendorId,
        locationId,
        e.target.value,
        lowStock,
      );
    }
    if (e.target.name === "lowStock") {
      setLowStock(e.target.value);
      getProductInventory(
        perPage,
        1,
        search,
        vendorId,
        locationId,
        range,
        e.target.value,
      );
    }
    if (e.target.name === "category") {
      //     setLowStock(e.target.value);
      getProductInventory(
        perPage,
        1,
        search,
        vendorId,
        locationId,
        range,
        lowStock,
        e.target.value,
        e.target.value ? subcategoryId : "",
      );
    }
    if (e.target.name === "subcategory") {
      setSubCategoryId(e.target.value);
      getProductInventory(
        perPage,
        1,
        search,
        vendorId,
        locationId,
        range,
        lowStock,
        categoryId,
        e.target.value,
      );
    }
  };
  const nextData = (pagination) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);
    getProductInventory(
      pagination.pageSize,
      pagination.current,
      search,
      vendorId,
      locationId,
      range,
      lowStock,
    );
  };
  const getProductInventory = async (
    perPage,
    page,
    search,
    vendorId,
    locationId,
    range,
    lowStock,
    category = categoryId,
    subcategory = subcategoryId,
  ) => {
    setLoading(true);
    setData([]);
    setTotalReacord(0);
    let params = {
      parentOrganizationId: organizationId,
      // storeId: userData.organizationId,
      search: search,
      vendorId: vendorId ? vendorId : "",
      storeId: locationId,
      lowStock: lowStock,
      limit: perPage,
      offset: page,
      categoryId: category,
      subcategoryId: subcategory,
      gmtTime: getUserData().time_gmt
    }
    if(!stateDate.current){
    if(startDate && endDate){
      params["startDate"] = startDate;
      params["endDate"] = endDate;
    }}
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PRODUCT_INVENTORY.service,
      ApiRoutes.PRODUCT_INVENTORY.url,
      ApiRoutes.PRODUCT_INVENTORY.method,
      ApiRoutes.PRODUCT_INVENTORY.authenticate,
      params
    );
    if (response.code === 200) {
      if (response.data.data) {
        setData(response.data.data ?? []);
        setTotalReacord(response.data.total || response.data.data.length);
        setLoading(false);
      } else {
        setData([]);
        setTotalReacord(0);
        setLoading(false);
        //  setNoDatAvailable(true);
      }
    } else {
      setData([]);
      setTotalReacord(0);
      setLoading(false);
    }
    stateDate.current = false;
  };
  const editProductInventory = async (id, data) => {
    setEditProductId(id);
    setEditData(data);
    setIsModalVisible(true);
  };
  const productInventoryUpdate = (value) => {
    // const response = await new ApiHelper().FetchFromServer(
    //   ApiRoutes.GETALLFILTER.service,
    //   ApiRoutes.GETALLFILTER.url + "?organizationId=" + props.organizationId,
    //   ApiRoutes.GETALLFILTER.method,
    //   ApiRoutes.GETALLFILTER.authenticate,
    //   undefined
    // );
    // if (response.code === 200) {
    //   setShiftTime(response.data.shiftData);
    //   setStoreLoction(response.data.organizationData);
    // } else {
    //   message.error({
    //     content: cMessage.reportHourError,
    //     duration: 2,
    //   });
    // }
    const tempData = data;
    const temp = editData;
    // temp.editProductId=value.editProductId, editProductId
    temp.itemname = value.productName;
    temp.currentQty = value.currentQty || 0;
    temp.alertAt = value.alertAt || 0;
    temp.costPrice = value.costPrice;
    temp.salePrice = value.salePrice;
    console.log(value);
    tempData.map((value, key) => {
      if (value.productInventoryId === editProductId) value = temp;

      return value;
    });
    setData(tempData);
    setIsModalVisible(false);
    getProductInventory(
      perPage,
      page,
      search,
      vendorId,
      locationId,
      range,
      lowStock
    );
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dateRangeCancel = () => {
    setStartDate(null)
    setEndDate(null)
    stateDate.current = true;
    getProductInventory(
      perPage,
      1,
      search,
      vendorId,
      locationId,
      range,
      lowStock,
    );
  }

  const expiryHandler = ( date, rowData ) => {
    let todayDate = moment(new Date()?.toISOString()?.split('T')[0]);
    let expiryDate = moment(date);
    let alert = rowData.expiryAlert ? parseInt(rowData.expiryAlert) : 0;
    let betweenDays = expiryDate.diff(todayDate, "days")
    return <span style={{ color: betweenDays <= alert && "red", fontWeight: betweenDays <= alert && "700" }}>{date}</span>
  }

  const columns = [
    {
      icon: "name",
      dataIndex: "productName",
      key: "name",
      fixed: "left",
      width: 50,
      padding: "8px 0 8px 10px!important",
      className: "first-p-r-0",
      render: (name) => (
        <>
          <div key={name}>
            <Avatar className="bg-primary fw-700">{name.charAt(0)}</Avatar>
          </div>
        </>
      )
    },
    {
      title: messages.itemName[lan],
      dataIndex: "productName",
      key: "productName",
      render: (productName, rowData) => {
        return <><span>{rowData.variation ? `${productName} ${rowData.variation}` : productName}</span></>
      } 
    },
    {
      title: messages.upc[lan],
      dataIndex: "upc",
      key: "upc"
    },

    {
      title: messages.inventoryCount[lan],
      dataIndex: "currentQty",
      key: "currentQty",
      render: (currentQty, data) => {
        if(data.expiryDt == null){ // For Variants
          return parseInt(data.alertAt) >= parseInt(currentQty) ? (
            <>
              <span style={{ color: "rgb(255 77 79)", fontWeight: 700 }}>
                <StarOutlined /> {parseInt(currentQty)}
              </span>
            </>
          ) : (
            parseInt(currentQty)
          );
        }else{ // For Standards
          return parseInt(data.alertAt) >= parseInt(data.currentStock) ? (
            <>
              <span style={{ color: "rgb(255 77 79)", fontWeight: 700 }}>
                <StarOutlined /> {parseInt(data.currentStock)}
              </span>
            </>
          ) : (
            parseInt(data.currentStock)
          );          
        }
      }
    },
    {
      title: messages.alertAt[lan],
      dataIndex: "alertAt",
      key: "alertAt",
      render: (alertAt) => {
        return parseInt(alertAt);
      }
    },
    {
      title: "Expiration Date",
      key: "expiryDt",
      dataIndex: "expiryDt",
      render: ( expiryDt, data ) => {
        return <>{ expiryDt ? expiryHandler(expiryDt, data) : "-" }</>
      }
    },
    {
      title: "Expiry Alert",
      key: "expiryAlert",
      dataIndex: "expiryAlert",
      render: (expiryAlert, data) => (
        <>{ expiryAlert ? parseInt(expiryAlert) : "-" }</>
      )
    },
    {
      title: messages.costPrice[lan],
      key: "costPrice",
      dataIndex: "costPrice",
      render: (amount) => (
        <>{`${currencySign} ${amount > 0 ? numberFormat(amount) : "0.00"}`}</>
      )
    },
    {
      title: messages.sellPrice[lan],
      dataIndex: "salePrice",
      key: "salePrice",
      render: (salePrice) => <>{`${currencySign} ${numberFormat(salePrice)}`}</>
    },
    {
      title: messages.totalProductValue[lan],
      dataIndex: "totalProductValue",
      key: "totalProductValue",
      render: (salePrice, data) =>{     
        let curQty = data.expiryDt == null ? parseFloat(data.currentQty) : parseFloat(data.currentStock)
        let sP = parseFloat(data.salePrice)
        const totalProductValue =  ( Number(curQty)) * (Number(sP))
        return <>{currencySign}  {numberFormat(totalProductValue)}</>
      }
    },

    {
      title: messages.lastUpdated[lan],
      key: "createDt",
      dataIndex: "createDt"
      /* render: (amount) => (
        <>{`${currencySign} ${amount > 0 ? numberFormat(amount) : "0.00"}`}</>
      ) */
    },
    {
      title: messages.action[lan],
      dataIndex: "productInventoryId",
      key: "productInventoryId",
      align: "right",
      render: (productInventoryId, data) => { 
        let variant = ( data?.variation && data?.isExpirable == 1 ) ? `?variant=${data?.productInventoryId}` : ""
        return <>
          <Link to={`/product-inventory/view/${data.organizationProductId}${variant}`} className="btn--small btn--view"><EyeOutlined /></Link>
        </>
      }
    }
  ];
  const downloadProductInventory = () => {
    let token = getToken()
    let firstDate = "";
    let lastDate = "";
    if(startDate){
      firstDate = `&startDate=${startDate}`
    }
    if(endDate){
      lastDate = `&endDate=${endDate}`
    }
    window.open(
      `${AppConfig.API_ENDPOINT}${ApiRoutes.EXPORT_PRODUCT.service}${ApiRoutes.EXPORT_PRODUCT.url}?parentOrganizationId=${organizationId}&search=${search}&vendorId=${vendorId}&storeId=${locationId}&organizationId=${organizationId}&range=${range}&categoryId=${categoryId}&subcategoryId=${subcategoryId}&lowStock=${lowStock}&gmtTime=${getUserData().time_gmt}${firstDate}${lastDate}&token=${token}`,
      "_blank"
    );
  };
  return (
    <>
      <HorizontalMenu />
      <Form form={form} className="productInventorySec">
        <Row
          className="filterBox"
          style={{ padding: "0 11px 14px 11px", margin: "0px" }}
          gutter={{ xs: 12, lg: 6 }}
        >
          <Col xl={6} lg={6} xs={24}>
            <Input
              placeholder={messages.searchBy[lan]}
              prefix={<SearchOutlined />}
              className="search-input"
              onKeyUp={handleSearchChange}
              name="search"
            />
          </Col>

          {userData.metaValue === "multiple-store" && locationId ? (
            <Col>
              <Select
                style={{ width: 225 }}
                placeholder={messages.selectLocation[lan]}
                showSearch
                showArrow="true"
                className="locationSelect"
                dropdownClassName="locationDropdown"
                optionFilterProp="children"
                onChange={(e) =>
                  filter({
                    target: {
                      name: "location",
                      value: e
                    }
                  })
                }
                defaultValue={parseInt(locationId)}
                name="location"
              >
                {storeLoction &&
                  storeLoction.map((store, key) => (
                    <Option value={parseInt(store.organizationId)} key={key}>
                      <ShopOutlined /> {store.organizationName}
                    </Option>
                  ))}
              </Select>
            </Col>
          ) : (
            ""
          )}
          {vendorData.length > 0 ? (
            <Col>
              <Select
                mode="multiple"
                placeholder={messages.selectVendor[lan]}
                className="locationSelect"
                dropdownClassName="locationDropdown"
                style={{ width: 150 }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) =>
                  filter({
                    target: {
                      name: "vendor",
                      value: e
                    }
                  })
                }
              >
                {vendorData &&
                  vendorData.map((item, key) => (
                    <Option value={item.orgVendorId} key={key}>
                      {item.vendorName}
                    </Option>
                  ))}{" "}
                ;
              </Select>
            </Col>
          ) : (
            ""
          )}
          {category.length > 0 ? (
            <Col>
              <Select
                placeholder={messages.selectCategory[lan]}
                showSearch
                allowClear
                showArrow="true"
                className="category"
                dropdownClassName="category"
                style={{ width: 150 }}
                optionFilterProp="children"
                onChange={(e) => getSubCategory((e !== undefined) ? e : "")}
                name="category"
              >
                {category.length > 0 &&
                  category.map((cat, key) => (
                    <Option value={parseInt(cat.categoryId)} key={key}>
                      <ShopOutlined /> {cat.categoryCode}
                    </Option>
                  ))}
              </Select>
            </Col>
          ) : (
            ""
          )}
          {subcategory.length > 0 ? (
            <Col>
              <Select
                placeholder={messages.selectSubCategory[lan]}
                showSearch
                allowClear
                showArrow="true"
                className="subcategory"
                dropdownClassName="subcategory"
                style={{ width: 150 }}
                optionFilterProp="children"
                onChange={(e) =>
                  filter({
                    target: {
                      name: "subcategory",
                      value: e ? e : ""
                    }
                  })
                }
                name="subcategory"
              >
                {subcategory.length > 0 &&
                  subcategory.map((cat, key) => (
                    <Option value={parseInt(cat.categoryId)} key={key}>
                      <ShopOutlined /> {cat.categoryCode}
                    </Option>
                  ))}
              </Select>
            </Col>
          ) : (
            ""
          )}

          <Col>
            <Select
              placeholder={messages.lowStock[lan]}
              allowClear
              showArrow="true"
              className="locationSelect"
              // dropdownClassName="locationDropdown"
              style={{ width: 175 }}
              onChange={(e) =>
                filter({
                  target: {
                    name: "lowStock",
                    value: e
                  }
                })
              }
              name="lowStock"
            >
              <Option value={true} key={0}>
                {" "}
                {messages.Yes[lan]}{" "}
              </Option>
              <Option value={false} key={1}>
                {" "}
                {messages.No[lan]}{" "}
              </Option>
            </Select>
          </Col>
          <Col xl={5} lg={5} xs={20} title="Expiration Report" style={{ display: "flex"}}>
            <RangePicker onChange={handleDateChange} value={ startDate && endDate ? ([moment(startDate), moment(endDate)]) : (["",""])} clearIcon={false}/>
            { startDate && endDate && <Button icon={<CloseCircleOutlined/>} onClick={dateRangeCancel}></Button>}
          </Col>
          {/* <Col>
            <Select
              style={{ width: 150 }}
              placeholder="Sort by Range"
              onChange={(e) =>
                filter({
                  target: {
                    name: "range",
                    value: e
                  }
                })
              }
              name="range"
            >
              <Option value="1">Stock: Low to High</Option>
              <Option value="2">Stock: High to Low</Option>
              <Option value="3">Sell Price: Low to High</Option>
              <Option value="4">Sell Price: High to Low</Option>
            </Select>
          </Col> */}
          <Col className="pr-0 ml-auto">
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={downloadProductInventory}
            >
              {messages.Export[lan]}
            </Button>
          </Col>
        </Row>
      </Form>
      <Content className="content-body" style={{ minHeight: 200 }}>
        <Row>
          <Col lg={24} xs={24}>
            <div
              className="bg-white"
              style={{
                margin: "14px 14px 0px 14px",
                padding: 14,
                minHeight: 200
              }}
            >
              <Table
                columns={columns}
                rowKey={(record) => record.walletTransactionHistoryId}
                dataSource={data}
                className="walletTable"
                pageIndex={page}
                scroll={{ x: 'max-content' }}
                //scroll={{ x: 1300 }}
                pagination={
                  /* parseInt(totalReacord) > perPage
                     ? {
                         pageSize: perPage,
                         total: totalReacord,
                         defaultCurrent: page
                       }
                     : false*/
                  {
                    pageSize: perPage,
                    total: totalReacord,
                    defaultCurrent: page,
                    pageSizeOptions: ['10', '20', '50'],
                    showSizeChanger: true,
                    locale: { items_per_page: `/ ${messages.page[lan]}` }
                  }
                }
                locale={{
                  emptyText: loading ? (
                    <Skeleton
                      paragraph={{ rows: 5 }}
                      loading={loading}
                      active
                    ></Skeleton>
                  ) : (
                    <Emptymsg
                      emptytitle={messages.emptyTitleProductInventory[lan]}
                    />
                  )
                }}
                onChange={nextData}
              />
            </div>
          </Col>
        </Row>
      </Content>
      {isModalVisible ? (
        <EditProduct
          isModalVisible={isModalVisible}
          editData={editData}
          productInventory={productInventoryUpdate}
          handleCancel={handleCancel}
          editProductId={editProductId}
          lan={lan}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default ProductInventory;
