import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { messages } from "../../utils/Common/Messages";
import { getUserData } from "../../utils/Helpers";
const HorizontalMenu = () => {
  const lan = getUserData().language ?? "EN";
  const webLite = getUserData()?.webLite ?? false;
  const appLite = getUserData()?.appLite ?? false;
  const timeClockManagement = getUserData().timeClockManagement ?? false;
  const [screenWidthSize, setScreenWidthSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidthSize(window.innerWidth);
      console.log(screenWidthSize);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);
  console.log("webLite", webLite, "appLite", appLite);
  return (
    <div className="horizontalMenu" style={{ margin: "14px 14px 14px 14px" }}>
      <span className="mr-5">
        <NavLink exact to={`/`} activeClassName="active">
          {messages.salesSummary[lan]}{" "}
        </NavLink>
      </span>
      <span className="mr-5">
        <NavLink exact to={`/itemsales`} activeClassName="active">
          {" "}
          {messages.itemSales[lan]}
        </NavLink>
      </span>
      {/* <span className="mr-5">
            <NavLink exact to={`/non-revenue-summary`} >
              {" "}
              {messages.nonRevenueSummary[lan]}
            </NavLink>
          </span> */}
      <span className="mr-5">
        <NavLink exact to={`/NonRevenueItemSales`} activeClassName="active">
          {" "}
          {messages.nonRevenueItemSales[lan]}
        </NavLink>
      </span>
      {/* {screenWidthSize >= 1366 ? */}
      {webLite != true && appLite != true ? (
        <>
          <span className="mr-5">
            <NavLink exact to={`/product-inventory`} activeClassName="active">
              {" "}
              {messages.productInventory[lan]}
            </NavLink>
          </span>
          {/* <span className="mr-5">
            <NavLink exact to={`/non-revenue-item`} >
              {" "}
              {messages.nonRevenueItem[lan]}
            </NavLink>
          </span> */}
        
          {/* : ''} */}
          {timeClockManagement ? <>
            <span className="mr-5">
              <NavLink
                exact
                to={`/time-clock-management`}
                activeClassName="active"
              >
                {" "}
                {messages.timeClockManagement[lan]}
              </NavLink>
              </span>
              <span className="mr-5">
              <NavLink
                exact
                to={`/payroll`}
                activeClassName="active"
              >
                {" "}
                {messages.payroll[lan]}
              </NavLink>
            </span>
          </>: (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <span className="mr-5">
        <NavLink exact to={`/giftcards`} activeClassName="active">
          {" "}
          {messages.giftCards[lan]}
        </NavLink>
      </span>
    </div>
  );
};
export default HorizontalMenu;
