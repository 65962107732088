import { Button, Col, Form, Input, Row,Tabs } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { StoreNotes } from "../store/StoreNotes";
import DisableIcon from "../../../../assets/images/support/ic_outline-disabled-visible.png";
import {
  PhoneOutlined,
  ShopOutlined,
  FlagOutlined,
  EnvironmentOutlined,
  MailOutlined,
  ArrowLeftOutlined,
  UserOutlined,
  EyeOutlined,
  ContainerOutlined
} from "@ant-design/icons";
import PaginatedTable from "../../../common/PaginatedTable";
import popupclose from "../../../../assets/images/support/popupclose-icon.svg";
import AxiosService from "../../../../utils/Config/ApiService";
import SupportLoader from "../../../../utils/Config/SupportLoader";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";

const MerchantDetails = () => {
  const [form] = Form.useForm();
  const [ismainloader, setismainloader] = useState(true);
  const [pageloader, setpageloader] = useState(true);
  const [isloader, setisloader] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [store, setstore] = useState([]);
  const [parameters, setparameters] = useState([]);
  const [merchantSearchKey, setMerchantSearchKey] = useState("");
  let [addToggle, setAddToggle] = useState(false);
  const [status, setstatus] = useState(false);
  let [merchantParametersToggle, setMerchantParametersToggle] = useState(false);
  let [viewToggle, setViewToggle] = useState(false);
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [statusKey, setStatusKey] = useState("enable")
  const [toggle, settoggle] = useState(false);
  const storeid = useParams()?.id;
  const [submitloader, setsubmitloader] = useState(false);
  let [userid, setuserid] = useState("");
  let [statusFilter, setStatusFilter] = useState("All");
  let [isLoading, setIsLoading] = useState(true);
  const [merchant, setmerchant] = useState({});
  const history = useHistory();
  const merchantid = useParams()?.id;
  const activeKey = sessionStorage.getItem('activePageKey')?sessionStorage.getItem('activePageKey'):"1"
  //useState is required if we need column filter
  const [column, setColumn] = useState([
    {
      heading: () => "#",
      cell: (row, i) => {
        return i;
      },
      hidden: false,
      width: "20",
      id: 1,
    },
    {
      heading: () => "Organization Name",
      cell: (row) => {
        return (
          <div className="displayFlex alignCenter">
            <div className="ogShortName mr-3">NZ</div>
            {row["organizationName"]}
          </div>
        );
      },
      hidden: false,
      width: "100",
      id: 2,
    },
    {
      heading: () => "Phone Number",
      cell: (row) => {
        const mobile = row["businessPhoneCode"] + "-" + row["businessPhone"];
        return mobile;
      },
      hidden: false,
      width: "100",
      id: 3,
    },
    {
      heading: () => "Username",
      cell: (row) => {
        return row["userName"];
      },
      hidden: false,
      width: "100",
      id: 4,
    },
    {
      heading: () => "Email",
      cell: (row) => {
        return row["email"];
      },
      hidden: false,
      width: "100",
      id: 5,
    },
    {
      heading: () => "Status",
      cell: (row) => {
        if (row["active"] === 1) {
          return (
            <span
              style={{
                color: "#488f42",
                backgroundColor: "#ebfce3",
                paddingLeft: "4px",
                paddingRight: "4px",
                fontWeight: "600",
              }}
            >
              Active
            </span>
          );
        } else if (row["active"] === 0) {
          return (
            <span
              style={{
                color: "#eb5f50",
                backgroundColor: "#ffeceb",
                paddingLeft: "4px",
                paddingRight: "4px",
                fontWeight: "600",
              }}
            >
              Inactive
            </span>
          );
        }
      },
      hidden: false,
      width: "100",
      id: 6,
    },
    {
      heading: () => "Actions",
      cell: (row) => {
        return (
          <>
            <Link
              to={`/support/store/${row["organizationId"]}`}
              className="btn--small btn--view"
            >
              <EyeOutlined />
            </Link>
          </>
        );
      },
      hidden: false,
      width: "20",
      id: 7,
    },
  ]);

  const getmerchantById = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.MERCHANTS.service}${ApiRoutes.MERCHANTS.url}/${merchantid}`);
      if (res.data.statusCode === 200) {
        // console.log(res.data.data[0]);
        setmerchant(res.data.data[0]);
        setismainloader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    getmerchantById();
  }, []);

  // //generate the options of multi select
  // column.forEach((e) => {
  //   let option = {
  //     label: e.id === 13 ? 'Status' : e.heading(),
  //     value: e.id
  //   }
  //   filterOptions.push(option)
  // })
  const getStoreList = useCallback(async () => {
    try {
      let res = await AxiosService.get(
        `${ApiRoutes.MERCHANT_STORE_LIST.service}${ApiRoutes.MERCHANT_STORE_LIST.url}/${merchantid}`,
        {
          params: {
            pgno: currentPage,
            limit: itemsPerPage,
            searchKey: searchKey,
          },
        }
      );
      if (res.status === 200) {
        setstore(res.data.data);
        setTotalCount(res.data.count);
        setIsLoading(true);
        setisloader(false);
        setpageloader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, itemsPerPage, searchKey, merchantid]);

  useEffect(() => {
    getStoreList();
  }, [currentPage, itemsPerPage, searchKey]);

  const handleDisableMerchant = async (values) => {
    try {
      setsubmitloader(true);
      const employeeId = JSON.parse(
        sessionStorage.getItem("userData")
      ).employeeId;
      let res = await AxiosService.put(
        `${ApiRoutes.MERCHANT_TOGGLE.service}${ApiRoutes.MERCHANT_TOGGLE.url}/${merchantid}`,
        {
          supportAdminId: employeeId,
          reason: values?.reason,
          status: status,
        }
      );
      if (res.status === 200) {
        setsubmitloader(false);
        setAddToggle(false);

        setisloader(true);
        getStoreList();
        getmerchantById(); 
        form.resetFields()  
      }
    } catch (error) {
      console.log(error);
      setsubmitloader(false);
    }
  };

  return (
    <>
      <div className="adminContentSection">
        <Row gutter={8}>
          <Col span={24}>
            <div className="displayFlex mt10 mb-05 spaceBetween">
              <Button
                type="warning"
                className="mr-3 btn--large d-flex align-items-center"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowLeftOutlined /> Back
              </Button>
              <div>

              {merchant?.status === 1 ? (
                <Button
                  type="primary"
                  className="btn--large"
                  onClick={() => {
                    setAddToggle(true);
                    // setstatus(false);
                    setStatusKey("disable")

                  }}
                >
                  <img
                    src={DisableIcon}
                    width="17px"
                    height="17px"
                    className="mr-1"
                    alt="disicon"
                  />{" "}
                  Disable
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="btn--large"
                  onClick={() => {
                    setAddToggle(true);
                    // setstatus(true);
                    setStatusKey("enable")
                  }}
                >
                  <img
                    src={DisableIcon}
                    width="17px"
                    height="17px"
                    className="mr-1"
                    alt="enableicon"
                  />{" "}
                  Enable
                </Button>
              )} 
              &nbsp;&nbsp;&nbsp;
              
                <Button
                  type="primary"
                  className="mr-3 btn--large"
                  onClick={() => {
                    history.push(`/support/order/${merchantid}`);
                  }}
                >
                  Orders
                </Button>
                <Button
                  type="primary"
                  className="btn--large"
                  onClick={() => history.push(`/support/merchant/${merchantid}/parameters?countryId=${merchant.country}`)}
                >
                  Merchant Parameters
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {ismainloader ? (
          <div className="profileDetailsLeft bg-white">
            <SupportLoader />
          </div>
        ) : (
          <div>
            <Row gutter={8} className="mb-05">
              <Col span={8}>
                <div className="profileDetailsLeft bg-white">
                  <div className="shortname">
                    {merchant?.firstName?.slice(0, 1).toUpperCase()}
                    {merchant?.lastName?.slice(0, 1).toUpperCase()}
                  </div>
                  <div className="fullname">
                    <p>
                      {" "}
                      {merchant?.firstName?.toUpperCase()}{" "}
                      {merchant?.lastName?.toUpperCase()}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={16}>
                <div className="profileDetailsRight bg-white">
                  <Row gutter={8}>
                    <Col span={12}>
                      <div className="displayFlex">
                        <div className="mr-3">
                          <ShopOutlined />
                        </div>
                        <div>
                          <p className="fw-700 mb-0">Business Name</p>
                          <p>{merchant?.organizationName}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="displayFlex">
                        <div className="mr-3">
                          <PhoneOutlined />
                        </div>
                        <div>
                          <p className="fw-700 mb-0">Business Phone</p>
                          <p>
                            {merchant?.businessPhoneCode +
                              "-" +
                              merchant?.businessPhone}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="displayFlex">
                        <div className="mr-3">
                          <UserOutlined />
                        </div>
                        <div>
                          <p className="fw-700 mb-0">Full Name</p>
                          <p>
                            {" "}
                            {merchant?.firstName} {merchant?.lastName}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="displayFlex">
                        <div className="mr-3">
                          <MailOutlined />
                        </div>
                        <div>
                          <p className="fw-700 mb-0">Email</p>
                          <p>{merchant?.email}</p>
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className="displayFlex">
                        <div className="mr-3">
                        <UserOutlined />
                        </div>
                        <div>
                          <p className="fw-700 mb-0">User Name</p>
                          <p>{merchant?.userName}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="displayFlex">
                        <div className="mr-3">
                          <FlagOutlined />
                        </div>
                        <div>
                          <p className="fw-700 mb-0">
                            Country / State / City Name
                          </p>
                          <p>
                            {merchant?.countryName
                              ? merchant?.countryName
                              : "-"}{" "}
                            {" / "}{" "}
                            {merchant?.stateName !== null
                              ? merchant?.stateName
                              : "-"}{" "}
                            {" / "}{" "}
                            {merchant?.cityName !== null
                              ? merchant?.cityName
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="displayFlex">
                        <div className="mr-3">
                          <EnvironmentOutlined />
                        </div>
                        <div>
                          <p className="fw-700 mb-0">Address</p>
                          <p>{merchant?.addressLine1}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="displayFlex">
                      { 
                      merchant?.resellerName !== null &&
                          merchant?.resellerName ?
                          <>
                        <div className="mr-3">
                          <ShopOutlined />
                        </div>
                        <div>
                        
                            
                              <p className="fw-700 mb-0">Reseller Name</p>
                              <p>{merchant?.resellerName}</p>
                            
                         
                        </div>
                        </>
                        :<></>}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
              <Tabs defaultActiveKey = {activeKey} className="supportTabs" onChange={()=>sessionStorage.removeItem('activePageKey')}>
              <Tabs.TabPane
                key="1"
                tab={
                  <span className="d-flex align-items-center">
                    <EnvironmentOutlined className="mr-1" />
                    Locations
                  </span>
                }
              >
                {!isloader ? (
                  <PaginatedTable
                    row={store}
                    column={column}
                    totalCount={totalCount}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    searchKey={searchKey}
                    setSearchKey={setSearchKey}
                    isFilterHeader={true}
                    isSearch={true}
                    isMultiSelect={false}
                    maxPageNumberLimit={maxPageNumberLimit}
                    setMaxPageNumberLimit={setMaxPageNumberLimit}
                    minPageNumberLimit={minPageNumberLimit}
                    setMinPageNumberLimit={setMinPageNumberLimit}
                    setpageloader={setpageloader}
                    pageloader={pageloader}
                  />
                ) : (
                  <SupportLoader />
                )}
              </Tabs.TabPane>
              
              <Tabs.TabPane
                tab={
                  <span className="d-flex align-items-center">
                    <ContainerOutlined className="mr-1" />
                    Merchant Notes
                  </span>
                }
                key="3"
              >
                <StoreNotes
                  storeid={storeid}
                  type={"Merchant"}
                  parentLoader={isloader}
                  toggle={toggle}
                />
              </Tabs.TabPane>
              
            </Tabs>
                
              </Col>
            </Row>
          </div>
        )}
                {addToggle ? (
          <div>
            <div className="divoverlay">
              <div className="divoverlayBlock">
                <div className="popupheaderblock">
                  <h5> Reason </h5>
                  <img
                    src={popupclose}
                    alt="Close"
                    className="cursorPointer"
                    onClick={() => setAddToggle(false)}
                  />
                </div>
                <div className="container-fluid">
                  <div className="overdivScroll">
                    <div className="row mb40">
                      <Form
                      form={form}
                        name="basic"
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                        style={{
                          maxWidth: 600,
                          margin: "10px 20px",
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        autoComplete="off"
                        className="supportForm"
                        onFinish={handleDisableMerchant}
                      >
                        <Form.Item
                          label="Reason"
                          name="reason"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the reason",
                            },
                            
                          ]}
                        >
                          <Input
                          name="reason"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                          
                            if (inputValue.startsWith(' ')) {
                              form.setFieldsValue({ reason: inputValue.trim() });
                            } else {
                              form.setFieldsValue({ reason: inputValue });
                            }
                          }}

                           placeholder="Enter Reason" />
                        </Form.Item>

                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                          className="text-right"
                        >
                          <div className="displayFlex justifyflexend alignCenter">
                            <Button
                              type="default"
                              onClick={() => {setAddToggle(false);form.resetFields()}}

                              className="mr-3 btn--cancel btn--large"
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className=" btn--large"
                              onClick={() => {
                                statusKey === "enable" ? setstatus(true) : setstatus(false);
                              }}

                            >
                              {submitloader ? <SupportLoader /> : "Submit"}
                            </Button>
                          </div>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default MerchantDetails;
