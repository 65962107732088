import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Input, Row, Switch, message } from 'antd'
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import PaginatedTable from '../../../common/PaginatedTable';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import Nodata from "../../../../assets/images/support/nodata.png";
import SupportLoader from '../../../../utils/Config/SupportLoader';
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';
import AxiosService from '../../../../utils/Config/ApiService';

const AgentList = () => {
  
  const [messageApi, contextHolder] = message.useMessage();
  const pageNumberLimit = 3

  const [pageloader, setpageloader] = useState(true)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  let [validToken, setValidToken] = useState(false);
  let renderRef = useRef(false);

  let [totalCount, setTotalCount] = useState(0)
  let [currentPage, setCurrentPage] = useState(1)
  let [itemsPerPage, setItemsPerPage] = useState(10)
  let [searchKey, setSearchKey] = useState("")
  let [agents, setAgents] = useState([])

  let [isloader, setisloader] = useState(true)

  let {search} = useLocation()
  let history = useHistory()
  const location = useLocation();
  
  const column = [
    {
      heading: () => '#',
      cell: (row, i) => {
        return i;
      },
      hidden: false,
      width: "20",
      id: 1
    },
    {
      heading: () => "Agent Name",
      cell: row => row["agentName"],
      hidden: false,
      width: "100",
      id: 2
    },
    {
      heading: () => "Agent Address",
      cell: row => row['agentAddress'],
      hidden: false,
      width: "100",
      id: 3
    },
    {
      heading: () => "Distributor Name",
      cell: row => row['distributorName'],
      hidden: false,
      width: "120",
      id: 4
    },
    {
      heading: () => "Phone",
      cell: row => row['phoneNumber'],
      hidden: false,
      width: "100",
      id: 5
    },
    {
      heading: () => "Status",
      cell: row => {              
        if(row['status'] === 1){
          return  <span style={{color : "#488f42" , backgroundColor: "#ebfce3", paddingLeft:"4px",paddingRight : "4px", fontWeight: "600"}}>Active</span>
        }else if(row['status'] === 0){
          return <span style={{color : "#eb5f50" , backgroundColor: "#ffeceb", paddingLeft:"4px", paddingRight : "4px",fontWeight: "600"}}>Inactive</span>
        }
      },
      hidden: false,
      width: "100",
      id: 6
    },
    {
      heading: () => "Actions",
      cell: row => {
          return <>
            {
              <>
              <Switch className='mr-1' defaultChecked={row['status'] === 1 ? true : false} onChange={()=>toggleDistributor(row['agentId'])} size='small'/>
              <Link to={`/distributor/agentAdmin/view/${row.agentId}`} className="btn--small btn--view"><EyeOutlined /></Link>
              </>
            }
          </>
      },
      hidden: false,
      width: "20",
      id: 7
    }
  ]

  const verifyToken = async()=>{
    let query = new URLSearchParams(search)
    let token;

    if(query.get('token'))
    {
      token = query.get('token')
      sessionStorage.setItem('token',query.get('token'))
    }
    else
    {
      token = sessionStorage.getItem('token')
    }

    if(token)
    {
      try {
        let res = await AxiosService.post(`${ApiRoutes.SUPPORT_VERIFY_TOKEN.service}${ApiRoutes.SUPPORT_VERIFY_TOKEN.url}`,{token})
        if(res.status===200)
        {
          sessionStorage.setItem('userData',JSON.stringify(res.data.userData))
          sessionStorage.setItem('supportToken',(res.data.token))
          setValidToken(true);
          history.push('/distributor/agentAdmin')
        }
      } catch (error) {
        if(error.response.status===400){
          alert('Session Expired')
          history.push('/')
        }
        else{
          alert('Error Occoured. Login Again')
          history.push('/')
        }
      }
    }
    else
    {
      alert('No token found')
      history.push('/')
    }
  }

  const getAgentList = async() => {      
    try{
      const response = await AxiosService.get(`${ApiRoutes.GET_AGENT.service}${ApiRoutes.GET_AGENT.url}`,{
        params : {
          pgno : currentPage,
          limit : itemsPerPage,
          searchKey : searchKey
        }
      });

      if(response.status === 200){
        setisloader(false)
        setAgents(response.data.data)
        setTotalCount(response.data.count)
        setpageloader(false) 
      }
    }catch(error){
      setpageloader(false)
      console.log(error)
      alert(error.response.data.error ?? error.response.data.message)
    }
  }

  const toggleDistributor = async (id) => {
    try{
      const response = await AxiosService.put(`${ApiRoutes.TOGGLE_AGENT.service}${ApiRoutes.TOGGLE_AGENT.url}${id}`);
      if(response.status === 200){
        getAgentList();
      }
    }catch(error){
      console.log(error)
      alert(error.response.data.error ?? error.response.data.message)
      getAgentList();
    }
  }

  useEffect(()=>{
    verifyToken()
  },[])

  useEffect(() => {
    if (renderRef.current == true) {
      const delayFn = setTimeout(() => {
        currentPage == 1 ? getAgentList() : setCurrentPage(1);
      }, 1000);
      return () => clearTimeout(delayFn);
    } else {
      renderRef.current = true;
    }
  }, [searchKey]);

  useEffect(() => {
      validToken && getAgentList();  
  },[currentPage, itemsPerPage, validToken])
  
  return (
    <>
    {contextHolder}
    <div className="adminContentSection">
      <Row gutter={8}>
        <Col span={10}>
          <Input 
            size="large"
            id="search"
            placeholder="Search by Agent Name, Distributor Name, Phone Number..." 
            prefix={<SearchOutlined />} 
            className="supportSearchBox"
            value={searchKey}
            onClick={() =>{
              setTotalCount(0)
            }}
            onChange={(e) => {
              const regex = /^[ ]/g
              if(regex.test(e.target.value)){
                e.target.value?.replace(/^[ ]/g,"")
              }else{
                setpageloader(true)
                setSearchKey(e.target.value)
              }
            }}
          />              
        </Col>
        <Col span={4}>
          <div>
            <select style={{width : "85%"}} 
              defaultValue={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
                setCurrentPage(1);
                setMaxPageNumberLimit(pageNumberLimit);
                setMinPageNumberLimit(0);
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </Col>
        <Button
          type="warning"
          htmlType="submit"
          className="btn--large mr-3"
          onClick={()=>history.push("/distributor/agentAdmin/bulkAdd")}
        >
          Bulk Upload
        </Button>
        <Button
          type="primary"
          className="btn--large"
          onClick={()=>history.push("/distributor/agentAdmin/add")}
        >
          {/* <img
            src={PlusIcon}
            width="17px"
            height="17px"
            className="mr-1"
            alt="enableicon"
          />{" "} */}
          Add Agent
        </Button> 
      </Row>   
        {
          isloader ? <SupportLoader/> :
          (!!agents.length) ? <PaginatedTable
            row={agents}
            column={column}
            totalCount={totalCount}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isFilterHeader={false}
            isSearch = {false}
            maxPageNumberLimit={maxPageNumberLimit}
            setMaxPageNumberLimit={setMaxPageNumberLimit}
            minPageNumberLimit={minPageNumberLimit}
            setMinPageNumberLimit={setMinPageNumberLimit}
            isloader = {isloader}
            setpageloader={setpageloader}
            pageloader={pageloader}
            isvisible = {false}            
          /> : <div className="text-center p-x8-y16 bg-white mt30"><img src={Nodata} alt='icon'/></div>
        }
    </div>
    </>
  )
}

export default AgentList