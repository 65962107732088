import React from 'react';
import { Layout } from 'antd';
import { messages } from "../../../utils/Common/Messages";
import { getUserData } from "../../../utils/Helpers";
import moment from "moment";
const { Footer } = Layout;
const lan = getUserData().language ?? "EN";

const Footerlayout = () => {
  return (
    <Footer className="text-center">&copy; {moment().format("YYYY")} {messages.CopyRight[lan]}</Footer>
  );
}
export default Footerlayout;
