import React, { useEffect } from "react";
import { Input, Modal, Form, message } from "antd";
import { ApiHelper } from "../../../../utils/Helpers";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import { messages } from "../../../../utils/Common/Messages";

const EditProduct = (props) => {
  const { editData, lan } = props;
  const [form] = Form.useForm();
  // const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    form.setFieldsValue({
      editProductId: props.editProductId,
      itemname: props.editData.productName,
      currentQty: parseInt(props.editData.currentQty) ||  0,
      alertAt: parseInt(props.editData.alertAt) ||  0,
      costPrice: props.editData.costPrice,
      salePrice: props.editData.salePrice
    });
    // eslint-disable-next-line
  }, [props.editData]);
  return (
    <>
      <Modal
        title={messages.editProductInventory[lan]}
        visible={props.isModalVisible}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              // setIsLoading(true);
              const response = await new ApiHelper().FetchFromServer(
                ApiRoutes.UPDATE_INVENTORY.service,
                ApiRoutes.UPDATE_INVENTORY.url,
                ApiRoutes.UPDATE_INVENTORY.method,
                ApiRoutes.UPDATE_INVENTORY.authenticate,
                undefined,
                {
                  organizationProductId: editData.organizationProductId,
                  productInventoryId: editData.productInventoryId,
                  productId: editData.productId,
                  productPriceId: editData.productPriceId,
                  currentQty: values.currentQty  ||  0,
                  costPrice: values.costPrice,
                  salePrice: values.salePrice,
                  alertAt: values.alertAt  ||  0,
                },
              );
              // setIsLoading(false);
              if (response.code === 200) {
                form.resetFields();
                props.productInventory(values);
                message.success({
                  content: response.messages[0],
                  duration: 2
                });
              }
              else {
                message.error({
                  content: response.messages[0],
                  duration: 2
                });
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        okText={messages.ok[lan]}
        cancelText={messages.cancel[lan]}
        onCancel={props.handleCancel}
      >
        {editData ? (
          <Form
            form={form}
            layout="vertical"
            name="reporting_hours_form"
          >
            <>
              <Form.Item
                name="itemname"
                label={messages.itemName[lan]}
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="currentQty"
                label={messages.currentQty[lan]}
                
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: messages.currentQtyNumber[lan]
                  }
                ]}
              >
                <Input maxlength="5"/>
              </Form.Item>
              <Form.Item
                name="alertAt"
                label={messages.alertAtQty[lan]}
                maxlength="5"
              
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: messages.alertAtNumber[lan]
                  }
                ]}
              >
                <Input maxlength="5" />
              </Form.Item>
              <Form.Item
                name="costPrice"
                label={messages.costPrice[lan]}
            
              
                rules={[
                  {
                    pattern: /^[0-9.]+$/,
                    message: messages.costPriceNumber[lan]
                  }
                ]}
              >
                <Input     maxlength="11" />
              </Form.Item>
              <Form.Item
                name="salePrice"
                label={messages.sellPrice[lan]}
                rules={[
                  {
                    required: true,
                    message: messages.sellPriceRequired[lan]
                  },
                  {
                    pattern: /^[0-9.]+$/,
                    message: messages.sellPriceNumber[lan]
                  }
                ]}
              >
                <Input maxlength="11"/>
              </Form.Item>
              <Form.Item name="editProductId" className="hiddenItem">
                <Input type="hidden" />
              </Form.Item>
            </>
          </Form>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};
export default EditProduct;
