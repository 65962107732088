export const AgentCard = ( details ) => {
    const qrBody = `<html lang="en">
      <head>
          <meta charset="utf-8">
          <title>Agent QR</title>
          <meta name="description" content="Ekikart">
          <meta name="author" content="SitePoint">
        <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
        <style media="all" type="text/css">
  
          body {
              font-family: "Sarabun", sans-serif;
              -webkit-font-smoothing: antialiased;
              font-size: 16px;
              line-height: 1.2;
              -ms-text-size-adjust: 100%;
              -webkit-text-size-adjust: 100%;
          }
  
          table {
              border-collapse: separate;
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
              width: 100%;
          }
  
          table td {
              font-family: "Sarabun", sans-serif;
              font-size: 16px;
              vertical-align: top;
              padding: 5px;
              color: #fff
          }
  
  
          body {
              background-color: #fff;
              margin: 0;
              padding: 0;
          }
  
          .body {
              background-color: #fff;
              width: 100%;
          }
  
          .container {
              margin: 0 auto !important;
              max-width: 400px;
              padding: 14px 0;
              width: 400px;
          }
  
          .content {
              box-sizing: border-box;
              display: block;
              margin: 0 auto;
              max-width: 400px;
              padding: 0;
          }
  
  
          .main {
              background: #30649B;
              border: 1px solid #eaebed;
              border-radius: 16px;
              width: 100%;
          }
  
          .wrapper {
              box-sizing: border-box;
              padding: 12px;
          }
  
          .wrapperQr {
              box-sizing: border-box;
              padding: 0 12px;
          }
  
          .header {
              box-sizing: border-box;
              padding: 32px 24px 24px 24px;
          }
  
  
          p {
              font-family: "Sarabun", sans-serif;
              font-size: 16px;
              font-weight: normal;
              margin: 0;
              margin-bottom: 16px;
          }
  
          a {
              color: #0867ec;
              text-decoration: underline;
          }
  
  
          .btn {
              box-sizing: border-box;
              min-width: 100% !important;
              width: 100%;
              padding-bottom: 0;
          }
  
          .btn>tbody>tr>td {
              padding-bottom: 16px;
          }
  
          .btn table {
              width: auto;
          }
  
          .btn table td {
              background-color: #ffffff;
              border-radius: 4px;
              text-align: center;
          }
  
          .btn a {
              background-color: #ffffff;
              border: solid 1px #30649B;
              border-radius: 10px;
              box-sizing: border-box;
              color: #000;
              cursor: pointer;
              display: inline-block;
              font-size: 14px;
              font-weight: 400;
              margin: 0;
              padding: 12px 44px;
              text-decoration: none;
          }
  
          .btn-primary table td {
              background-color: #30649B;
          }
  
          .btn-primary a {
              background-color: #30649B;
              border-color: #30649B;
              color: #ffffff;
              font-size: 14px;
          }
  
          @media all {
              .btn-primary table td:hover {
                  background-color: #30649B !important;
              }
  
              .btn-primary a:hover {
                  background-color: #30649B !important;
                  border-color: #30649B !important;
              }
          }
  
          .last {
              margin-bottom: 0;
          }
  
          .first {
              margin-top: 0;
          }
  
          .align-center {
              text-align: center;
          }
  
          .align-right {
              text-align: right;
          }
  
          .align-left {
              text-align: left;
          }
  
          .text-link {
              color: #fff !important;
              text-decoration: none !important;
          }
  
          .clear {
              clear: both;
          }
  
          .mt0 {
              margin-top: 0;
          }
  
          .mb0 {
              margin-bottom: 0;
          }
  
          .qrscan {
              border: 2px dashed #FFFFFF;
              border-radius: 10px;
              background-color: #4F7BAA;
              padding: 14px 24px 10px 24px !important;
          }
  
          @media only screen and (max-width: 640px) {
  
              .main p,
              .main td,
              .main span {
                  font-size: 16px !important;
              }
  
              .wrapper {
                  padding: 8px !important;
              }
  
              .content {
                  padding: 0 !important;
              }
  
              .container {
                  padding: 0 !important;
                  padding-top: 8px !important;
                  width: 100% !important;
              }
  
              .main {
                  border-left-width: 0 !important;
                  border-radius: 0 !important;
                  border-right-width: 0 !important;
              }
  
              .btn table {
                  max-width: 100% !important;
                  width: 100% !important;
              }
  
              .btn a {
                  font-size: 16px !important;
                  max-width: 100% !important;
                  width: 100% !important;
              }
          }
  
          @media all {
              .ExternalClass {
                  width: 100%;
              }
  
              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                  line-height: 100%;
              }
  
              .apple-link a {
                  color: inherit !important;
                  font-family: inherit !important;
                  font-size: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                  text-decoration: none !important;
              }
  
              #MessageViewBody a {
                  color: inherit;
                  text-decoration: none;
                  font-size: inherit;
                  font-family: inherit;
                  font-weight: inherit;
                  line-height: inherit;
              }
          }
      </style>
      </head>
      <body>
      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
      <tr>
          <td>&nbsp;</td>
          <td class="container">
              <div class="content">
  
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="main">
  
                      <tr>
                          <td class="header" align="center">
                              <svg width="127" height="40" viewBox="0 0 127 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1540_1787)">
                              <path d="M24.8334 14.1242V20.2133H35.5182V23.9751H20.0918V1.24854H35.4864V5.04131H24.8334V14.1242Z" fill="white"/>
                              <path d="M48.2745 17.2011L46.6304 18.8247V23.9751H42.0625V0H46.6304V13.2826L47.5154 12.1598L51.8937 7.08479H57.3784L51.1983 14.1256L57.916 23.9751H52.6682L48.2745 17.2011Z" fill="white"/>
                              <path d="M59.1478 2.71584C59.1378 2.40547 59.1938 2.09649 59.3121 1.8088C59.4305 1.52112 59.6086 1.26112 59.835 1.04558C60.3633 0.621587 61.0233 0.390137 61.7041 0.390137C62.3849 0.390137 63.045 0.621587 63.5733 1.04558C63.8015 1.26005 63.9813 1.51971 64.1008 1.80755C64.2203 2.09538 64.2769 2.4049 64.2669 2.71584C64.2774 3.03 64.2201 3.34277 64.0988 3.63338C63.9775 3.92399 63.795 4.18579 63.5633 4.40137C63.0396 4.82514 62.3835 5.05671 61.7064 5.05671C61.0294 5.05671 60.3732 4.82514 59.8495 4.40137C59.6182 4.18564 59.436 3.92378 59.315 3.63318C59.194 3.34257 59.137 3.02987 59.1478 2.71584ZM64.0004 23.9747H59.4166V7.08798H63.9999L64.0004 23.9747Z" fill="white"/>
                              <path d="M75.0766 14.8594L72.6108 17.4818V23.9751H67.8691V1.24854H72.6108V11.5503L74.6973 8.72496L80.5614 1.24854H86.395L78.2219 11.3473L86.6301 23.9747H80.988L75.0766 14.8594Z" fill="white"/>
                              <path d="M97.7941 23.9752C97.5613 23.4976 97.4068 22.9866 97.3361 22.4612C96.7967 23.0597 96.1305 23.5336 95.3847 23.8493C94.639 24.165 93.832 24.3147 93.0211 24.2878C91.4995 24.3416 90.0131 23.8286 88.8566 22.8506C88.3258 22.4063 87.9022 21.8506 87.6165 21.224C87.3308 20.5975 87.1903 19.9159 87.2053 19.2289C87.1648 18.4304 87.3281 17.6346 87.6802 16.9146C88.0323 16.1946 88.5619 15.5735 89.2204 15.1083C90.5645 14.1502 92.5058 13.6663 95.0445 13.6568H97.1465V12.6903C97.1813 12.0136 96.9652 11.3476 96.5384 10.817C96.2908 10.5668 95.9901 10.374 95.6579 10.2524C95.3257 10.1309 94.9703 10.0837 94.6174 10.1141C93.9675 10.0765 93.3256 10.2704 92.8083 10.6607C92.5903 10.8445 92.4179 11.0752 92.3041 11.3349C92.1902 11.5947 92.1381 11.8767 92.1516 12.1594H87.5841C87.5845 11.1801 87.9062 10.2273 88.501 9.4436C89.1676 8.56898 90.0641 7.89144 91.0933 7.48456C92.285 6.99681 93.5653 6.75517 94.8553 6.77451C96.9637 6.77451 98.6367 7.29743 99.8743 8.34327C101.112 9.38911 101.731 10.8589 101.731 12.7527V20.0733C101.742 21.676 101.969 22.8886 102.411 23.7111V23.9761L97.7941 23.9752ZM94.0166 20.8377C94.6659 20.8437 95.3065 20.6908 95.8813 20.3926C96.415 20.1297 96.8557 19.7136 97.1456 19.1988V16.2949H95.4374C93.1516 16.2949 91.9346 17.0754 91.7864 18.6365L91.7709 18.902C91.7636 19.1626 91.8136 19.4217 91.9173 19.6615C92.021 19.9013 92.176 20.116 92.3717 20.2911C92.8285 20.6755 93.4179 20.8711 94.0171 20.8372L94.0166 20.8377Z" fill="white"/>
                              <path d="M114.532 11.3164C113.988 11.2389 113.439 11.1972 112.888 11.1915C111.16 11.1915 110.027 11.7689 109.49 12.9237V23.9751H104.922V7.08838H109.237L109.363 9.10177C110.28 7.55142 111.55 6.77625 113.173 6.77625C113.654 6.7728 114.134 6.84124 114.595 6.97925L114.532 11.3164Z" fill="white"/>
                              <path d="M122.989 2.93408V7.08615H125.911V10.3952H122.987V18.8238C122.943 19.2997 123.072 19.7755 123.351 20.1662C123.543 20.3236 123.765 20.4407 124.004 20.5102C124.243 20.5797 124.494 20.6002 124.742 20.5704C125.192 20.5752 125.642 20.5385 126.085 20.4608V23.8795C125.189 24.1496 124.256 24.2858 123.319 24.2837C120.116 24.2837 118.483 22.6863 118.419 19.4916V10.3952H115.924V7.08615H118.422V2.93408H122.989Z" fill="white"/>
                              <path d="M36.9229 26.7928L12.9851 26.3087L7.08788 17.3659C7.08788 17.3659 5.12183 14.6627 9.75613 14.5935C12.101 14.5585 34.1123 14.5257 36.0193 14.5037V10.8178L26.3268 10.7765V10.781H4.28047C4.28047 10.781 -0.633517 10.4347 0.0686763 15.9804C0.77087 21.5261 7.93015 30.6763 9.75567 31.7852C36.3608 33.3122 37.4136 33.5188 37.4136 33.5188L36.9229 26.7928Z" fill="#FFDA60"/>
                              <path d="M20.7758 39.9999C21.9776 39.9999 22.9519 39.0378 22.9519 37.8509C22.9519 36.6641 21.9776 35.7019 20.7758 35.7019C19.5739 35.7019 18.5996 36.6641 18.5996 37.8509C18.5996 39.0378 19.5739 39.9999 20.7758 39.9999Z" fill="#30649B"/>
                              <path d="M30.3226 39.9999C31.5245 39.9999 32.4988 39.0378 32.4988 37.8509C32.4988 36.6641 31.5245 35.7019 30.3226 35.7019C29.1208 35.7019 28.1465 36.6641 28.1465 37.8509C28.1465 39.0378 29.1208 39.9999 30.3226 39.9999Z" fill="#30649B"/>
                              <path d="M34.0215 36.0603V36.7537H42.1649L43.2878 36.0603H34.0215Z" fill="#F9D341"/>
                              <path d="M34.0215 38.4173H38.1237L38.6903 37.7239H34.0215V38.4173Z" fill="#F9D341"/>
                              </g>
                              <defs>
                              <clipPath id="clip0_1540_1787">
                              <rect width="126.087" height="40" fill="white"/>
                              </clipPath>
                              </defs>
                              </svg>                 
                          </td>
                      </tr>
                      <tr>
                          <td class="wrapper" style="padding-bottom: 14px">
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                  <tbody>
                                      <tr>
                                          <td> Agent Name</td>
                                          <td align="right"> ${details?.agentName}</td>
                                      </tr>
                                      <tr>
                                          <td> Distributor Name</td>
                                          <td align="right"> ${details?.distributorName}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td class="wrapperQr">
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                  <tbody>
                                      <tr>
                                          <td class="wrapper qrscan" align="center">
                                              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                                  <tbody>
                                                      <tr>
                                                          <td align="center">
                                                              <img src="${details?.image}" />
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td class="btn" align="center">
                                                              <a href="http://htmlemail.io" target="_blank">Save to
                                                                  Wallet</a>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
  
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td class="wrapper">
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                  <tbody>
                                      <tr>
                                          <td class="" align="left">
                                              <p style="margin-bottom: 5px;"><strong> Privacy Policy</strong></p>
                                              <p style="margin-bottom: 15px;"><a href="https://spacepointe.com/privacynotice"
                                                      target="_blank" class="text-link">Privacy Policy</a></p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="" align="left">
                                              <p style="margin-bottom: 5px;"><strong>Website to Brand</strong></p>
                                              <p style="margin-bottom: 15px;"> <a href="http://ekikart.com"
                                                      target="_blank" class="text-link">www.ekikart.com</a></p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="" align="left">
                                              <p style="margin-bottom: 5px;"><strong>Disclaimer</strong></p>
                                              <p style="margin-bottom: 0px;">Disclaimer</p>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                  </table>
              </div>
          </td>
          <td>&nbsp;</td>
      </tr>
      </table>        
      </body>
      </html>
      `
      return qrBody
}