import React, { useEffect, useState } from "react";
import { Row, Col, message, Input } from "antd";
import Rangepicker from "../layout/filter/Rangepicker";
import Selectsummary from "../layout/filter/Selectsummary";
import Allday from "../layout/filter/Allday";
import Location from "../layout/filter/Location";
import Exportbutton from "../layout/Exportbutton";
import ExportbuttonNonRevenue from "../layout/ExportbuttonNonRevenue";
import Exportbuttonwithoutdropdown from "../layout/Exportbuttonwithoutdropdown";
import { ApiRoutes } from "../../utils/Config/ApiRoutes";
import { ApiHelper, message as cMessage,getUserData } from "../../utils/Helpers";
import Employee from "../layout/filter/Employee";
// import { messages } from "../../utils/Common/Messages";

const Filter = (props) => {
  const { filterData, organizationId, lan, userData,currencySign } = props;
  const { Search } = Input;
  // const { Option } = Select;
  // const query = new URLSearchParams(props.location.search);
  const [timeZone, setTimeZone] = useState([]);
  const [storeLoction, setStoreLoction] = useState([]);
  const [selectedStore,setSelectedStore] = useState("")
  const [employeeList,setEmployeeList] = useState([])
  const [shiftTime, setShiftTime] = useState([]);
  const [empSelected,setEmpSelected] = useState()
  const download = props.download ?? 0;
  const getFilterData = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GETALLFILTER.service,
      ApiRoutes.GETALLFILTER.url + "?organizationId=" + props.organizationId,
      ApiRoutes.GETALLFILTER.method,
      ApiRoutes.GETALLFILTER.authenticate,
      undefined
    );
    if (response.code === 200) {
      setShiftTime(response.data.shiftData);
      setStoreLoction(response.data.organizationData);
    } else {
      message.error({
        content: cMessage.reportHourError,
        duration: 2,
      });
    }
  };
  const searchData = (
    sDate = filterData.startDate,
    eDate = filterData.endDate,
    shiftId = filterData.shiftId,
    type = filterData.interval,
    sId = filterData.sId,
    employeeId = filterData.employeeId?filterData.employeeId:"",
    limit = "",
    summery = 1
  ) => {
    props.searchData(
      sDate || filterData.startDate,
      eDate || filterData.endDate,
      shiftId,
      type || filterData.interval,
      sId,
      employeeId,
      limit,
      summery
    );
  };
  const getEmployeeList = async()=>{
    const userData = getUserData();
    if(userData.organizationTypeId===2 && (selectedStore=="" || selectedStore==undefined))
    {
     let orgs =  storeLoction.map((e)=>e.organizationId)
      setSelectedStore(orgs.join(','))
    }
    else if(userData.organizationTypeId===1)
    {
      setSelectedStore(userData.organizationId)
    }

    console.log("organizationId="+selectedStore)
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GETEMPLOYEELIST.service,
      ApiRoutes.GETEMPLOYEELIST.url + `?organizationId=${selectedStore}`,
      ApiRoutes.GETEMPLOYEELIST.method,
      ApiRoutes.GETEMPLOYEELIST.authenticate,
      undefined
    );
    if (response.code === 200) {
      setEmployeeList(response.data.employeeList)
    } else {
      message.error({
        content: cMessage.reportHourError,
        duration: 2,
      });
    }
  }

  useEffect(()=>{
    getFilterData();
  },[])
  useEffect(()=>{
    getEmployeeList()
  },[storeLoction,selectedStore])
  return (
    <Row
      className="filterBox"
      style={{ padding: "0 14px 14px 14px", margin: "0px" }}
      gutter={{ xs: 12, lg: 6 }}
    >
      {props.pageType === "sale" || props.pageType === "item" || props.pageType === "nonRevenue" ?
        <>
          <Col className="pl-0">
            <Rangepicker
              filterdata={searchData}
              startDate={filterData.startDate}
              endDate={filterData.endDate}
              lan={lan}
            />
          </Col>
          <Col>
            <Allday
              selectvalue={shiftTime.find(element => element.shiftHourId === filterData.shiftId)?.shiftName || ""}
              timeZone={timeZone}
              shiftTime={shiftTime}
              ShiftTimeUpdate={setShiftTime}
              setTimeZone={setTimeZone}
              filterdata={searchData}
              organizationId={organizationId}
              employeeId={filterData.employeeId}
            />
          </Col>
          <Col>
            <Selectsummary
              data={filterData}
              selectvalue={filterData.interval}
              filterdata={searchData}
            />
          </Col>
          <Col>
          {/* <ItemType
              data={filterData}
              selectvalue={filterData.interval}
              filterdata={searchData}
            /> */}
          </Col>
          
        </> : <>
          <Col>
            <Search placeholder="Search by name, UPC" onSearch={""} />
          </Col>
          
        </>}

      {userData.metaValue === "multiple-store" ? (
        <Col>
          <Location 
            storeLoction={storeLoction} 
            setEmpSelected={setEmpSelected} 
            filterdata={searchData} 
            setSelectedStore={setSelectedStore}/>
        </Col>
      ) : (
        ""
      )}
      {props.pageType === "sale" || props.pageType === "nonRevenue"?<Col>
        <Employee 
          employeeList={employeeList} 
          filterdata={searchData} 
          empSelected={empSelected} 
          setEmpSelected={setEmpSelected}
          employeeId={filterData.employeeId}
          />
      </Col>:<></>}
      {download === 0 || download === "0" ? (
        <Col className="pr-0 ml-auto">
        {(() => {
          if (props.pageType === "sale") {
            return (
              <Exportbutton
                startDate={filterData.startDate}
                endDate={filterData.endDate}
                organizationId={organizationId}
                shiftId={filterData.shiftId}
                storeId={filterData.sId}
                employeeId={filterData.employeeId}
                lan={lan}
                currencySign={currencySign}
              />
            );
          } else if (props.pageType === "nonRevenue") {
            return (
              <ExportbuttonNonRevenue
              startDate={filterData.startDate}
              endDate={filterData.endDate}
              organizationId={organizationId}
              shiftId={filterData.shiftId}
              storeId={filterData.sId}
              employeeId={filterData.employeeId}
              lan={lan}
              defaultCurrencySign={currencySign}
            />
            );
          } else {
            return (
              <Exportbuttonwithoutdropdown
                startDate={filterData.startDate}
                endDate={filterData.endDate}
                organizationId={organizationId}
                shiftId={filterData.shiftId}
                storeId={filterData.sId}
                employeeId={filterData.employeeId}
                lan={lan}
                defaultCurrencySign={currencySign}
              />
            );
          }
        })()}
      </Col>
      ) : (
        ""
      )}
    </Row>
  );
};
export default Filter;
