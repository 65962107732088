import Salessummary from "../../components/views/dashboard/sales-summary/Salessummary";
// import NonRevenueSummary from "../../components/views/dashboard/non-revenue-summary/NonRevenueSummary";
import Salessummaryempty from "../../components/views/dashboard/sales-summary/Salessummaryempty";
import Proxylogin from "../../components/views/dashboard/proxy-login/Proxylogin";
import Itemsales from "../../components/views/dashboard/item-sales/Itemsales";
import NonRevenueItemSales from "../../components/views/dashboard/non-revenue-item-sales/NonRevenueItemSales";
import Transactionsummary from "../../components/views/dashboard/wallet/index";
import Layout from "../../components/common/Layout";
import WithHeaderFooterLayout from "../../components/common/WithHeaderFooterLayout";
import Allloader from "../../components/layout/loader/Loader";
import ProductInventory from "../../components/views/dashboard/product-inventory/index";
import NonRevenueItem from "../../components/views/dashboard/non-revenue-item/index";
import TimeClockManagement from "../../components/views/dashboard/time-clock-management/index";
import SellPage from "../../components/views/dashboard/sell/index";
import Support  from "../../components/views/support/index";
import MerchantDetails from "../../components/views/support/merchant/MerchantDetails";
import Order from "../../components/views/support/order/Order";
import OrderDetails from "../../components/views/support/order/OrderDetails";
import StaffProfile from "../../components/views/support/store/staff/StaffProfile";
import Parameters from '../../components/views/support/merchant/Parameters'
import StaffEdit from "../../components/views/support/store/staff/StaffEdit";
import ReceiptById from "../../components/views/support/store/ReceiptById";
import AddEmployee from "../../components/views/support/AddEmployee";
import AdminStaffProfile from "../../components/views/support/admin/AdminStaffProfile";
import AdminStaffEditProfile from "../../components/views/support/admin/AdminStaffEditProfile";
import GiftCards from "../../components/views/dashboard/gift-cards/GiftCards";
import StoreDetails from "../../components/views/support/store/StoreDetails";
import DistributorList from "../../components/views/distributor/distributor/DistributorList";
import AddDistributor from "../../components/views/distributor/distributor/AddDistributor";
import ViewDistributor from "../../components/views/distributor/distributor/ViewDistributor";
import EditDistributor from "../../components/views/distributor/distributor/EditDistributor";
import AgentList from "../../components/views/distributor/agent/AgentList";
import AddAgent from "../../components/views/distributor/agent/AddAgent";
import ViewAgent from "../../components/views/distributor/agent/ViewAgent";
import EditAgent from "../../components/views/distributor/agent/EditAgent";
import BulkAgent from "../../components/views/distributor/agent/BulkAgent";
import CampaignList from "../../components/views/distributor/campaign/CampaignList";
import AddCampaign from "../../components/views/distributor/campaign/AddCampaign";
import ViewCampaign from "../../components/views/distributor/campaign/ViewCampaign";
import EditCampaign from "../../components/views/distributor/campaign/EditCampaign";
import LeadList from "../../components/views/distributor/lead/LeadList";
import ViewLead from "../../components/views/distributor/lead/ViewLead";
import Payroll from '../../components/views/dashboard/payroll'
import MerchantList from "../../components/views/distributor/merchant/MerchantList";
import ViewMerchant from "../../components/views/distributor/merchant/ViewMerchant";
import ViewProductExpiry from "../../components/views/dashboard/product-inventory/viewProduct";

export const AppRouter = [
  {
    path: "/",
    exact: true,
    name: "Home",
    layout: Layout,
    component: Salessummary,
  },
  {
    path: "/salessummaryempty",
    exact: true,
    name: "Sale summary empty",
    layout: Layout,
    component: Salessummaryempty,
  },
  {
    path: "/itemsales",
    exact: true,
    name: "item Sales",
    layout: Layout,
    component: Itemsales,
  },
  {
    path: "/NonRevenueItemsales",
    exact: true,
    name: "item Sales",
    layout: Layout,
    component: NonRevenueItemSales,
  },
  {
    path: "/giftcards",
    exact: true,
    name: "item Sales",
    layout: Layout,
    component: GiftCards,
  },
  {
    path: "/product-inventory",
    exact: true,
    name: "Product Inventory",
    layout: Layout,
    component: ProductInventory,
  },
  {
    path: "/product-inventory/view/:id",
    exact: true,
    name: "View Product Expiry",
    layout: Layout,
    component: ViewProductExpiry,
  },
  {
    path: "/non-revenue-item",
    exact: true,
    name: "Non Revenue Item",
    layout: Layout,
    component: NonRevenueItem,
  },
  // {
  //   path: "/non-revenue-summary",
  //   exact: true,
  //   name: "Non Revenue Summary",
  //   layout: Layout,
  //   component: NonRevenueSummary,
  // },

  {
    path: "/wallet",
    exact: true,
    name: "Transaction Summary",
    layout: Layout,
    component: Transactionsummary,
  },
  {
    path: "/allloader",
    exact: true,
    name: "All Loader",
    layout: Layout,
    component: Allloader,
  },
  {
    path: "/proxy-login",
    exact: true,
    name: "Proxy Login",
    layout: WithHeaderFooterLayout,
    component: Proxylogin,
  },
  {
    path: "/time-clock-management",
    exact: true,
    name: "Time Clock Management",
    layout: Layout,
    component: TimeClockManagement,
  },
  {
    path: "/payroll",
    exact: true,
    name: "Payroll",
    layout: Layout,
    component: Payroll,
  },
  {
    path: "/sell",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: SellPage,
  },
  {
    path: "/support",
    exact: true,
    name: "Support",
    layout: WithHeaderFooterLayout,
    component: Support,
  },
  {
    path: "/support/merchant/:id",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: MerchantDetails,
  },
  {
    path: "/support/merchant/:id/parameters",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: Parameters,
  },
  {
    path: "/support/store/:id",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: StoreDetails,
  },
  {
    path: "/support/store/staff/:id",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: StaffProfile,
  },
  {
    path: "/support/store/staffEdit/:id",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: StaffEdit,
  },
  {
    path : "/support/store/receipt/:id",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : ReceiptById
  },
  {
    path: "/support/adminStaffProfile/:id",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: AdminStaffProfile,
  },
  {
    path: "/support/adminStaffProfileEdit/:id",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: AdminStaffEditProfile,
  },
  {
    path: "/support/order/:id",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: Order,
  },
  {
    path: "/support/orderDetail/:id",
    exact: true,
    name: "Sell",
    layout: WithHeaderFooterLayout,
    component: OrderDetails,
  },
  {
    path:"/support/addStaff/:storeid",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : AddEmployee
  },
  {
    path:"/distributor/distributorAdmin",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : DistributorList
  },
  {
    path:"/distributor/distributorAdmin/add",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : AddDistributor
  },
  {
    path:"/distributor/distributorAdmin/view/:id",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : ViewDistributor
  },
  {
    path:"/distributor/distributorAdmin/edit/:id",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : EditDistributor
  },
  {
    path:"/distributor/agentAdmin",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : AgentList
  },
  {
    path:"/distributor/agentAdmin/add",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : AddAgent
  },
  {
    path:"/distributor/agentAdmin/bulkAdd",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : BulkAgent
  },
  {
    path:"/distributor/agentAdmin/view/:id",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : ViewAgent
  },
  {
    path:"/distributor/agentAdmin/edit/:id",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : EditAgent
  },
  {
    path:"/distributor/campaignAdmin",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : CampaignList
  },
  {
    path:"/distributor/campaignAdmin/add",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : AddCampaign
  },
  {
    path:"/distributor/campaignAdmin/view/:id",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : ViewCampaign
  },
  {
    path:"/distributor/campaignAdmin/edit/:id",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : EditCampaign
  },
  {
    path:"/distributor/leadAdmin",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : LeadList
  },
  {
    path:"/distributor/leadAdmin/view/:id",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : ViewLead
  },
  {
    path:"/distributor/merchantApplicationForm",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : MerchantList
  },
  {
    path:"/distributor/merchantApplicationForm/view/:id",
    exact : true,
    name : "Sell",
    layout : WithHeaderFooterLayout,
    component : ViewMerchant
  },
];
