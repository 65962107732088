import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Form, Input, Row, Select, message } from 'antd'
import { useHistory } from 'react-router-dom';
import AxiosService from "../../../../utils/Config/ApiService";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import SupportLoader from '../../../../utils/Config/SupportLoader';

const AddAgent = () => {

  const history = useHistory();
  
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loader, setLoader] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [phoneCode, setPhoneCode] = useState([]);

  const [search, setSearch] = useState("")

  let [distributors, setDistributors] = useState([]);

  const handleFinish = async ( values ) => {
    values['businessPhoneCode'] = "+" + phoneCode.find(e => e.countryId == form.getFieldValue("country"))?.phoneCode
    console.log("ADD AGENT", values)
    setLoader(true)
    try {
      let res = await AxiosService.post(`${ApiRoutes.ADD_AGENT.service}${ApiRoutes.ADD_AGENT.url}`, values);
      if(res.status === 201){
        setLoader(false);
        history.goBack();
      }
    } catch (error) {
      setLoader(false);
      messageApi.open({
        type: "error",
        content: error.response.data.error ?? error.response.data.message
      })
      console.log(error)
    }
  }

  const handleGetDistributors = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.DISTRIBUTOR_MENU.service}${ApiRoutes.DISTRIBUTOR_MENU.url}`,{
        params: {
        util: "agent"
        }
      });
      console.log("RES", res.data)
      if (res.data.data.length > 0) {
        setDistributors([
          ...res.data.data?.map((ele) => ({
            value: ele?.distributorId,
            label: ele?.distributorName,
            status: ele?.status
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCountries = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.COUNTRY.service}${ApiRoutes.COUNTRY.url}`);
      if (res.data.statusCode === 200) {
        setCountries([
          ...res.data.data?.map((ele) => ({
            value: ele?.countryId,
            label: ele?.name,
          })),
        ]);
        setPhoneCode([
          ...res.data.data?.map((ele) => ({
            countryId: ele?.countryId,
            phoneCode: ele?.phoneCode,
          })),  
        ])        
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetStates = async (id) => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.STATE.service}${ApiRoutes.STATE.url}/${id}`);
      if (res.data.statusCode === 200) {
        setStates([
          ...res.data.data?.map((ele) => ({
            value: ele?.stateId,
            label: ele?.stateName,
          })),
        ])
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCities = async (id) => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.CITY.service}${ApiRoutes.CITY.url}/${id}`);
      if (res.data.data.length > 0) {
        setCities([
          ...res.data.data?.map((ele) => ({
            value: ele?.cityId,
            label: ele?.cityName,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterDistributors = (key) => {
    let data = distributors?.filter(e => e?.label?.toLowerCase()?.includes(key?.toLowerCase()))
    return data
  }

  useEffect(()=>{
    handleGetDistributors();
    handleGetCountries() 
    // By Default USA Is Selected, Hence We Are Getting States Of USA
    handleGetStates(1)
  },[])

  return (
    <>
    {contextHolder}
    <div className='bg-white' style={{ padding: "20px" }}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        className="editProfileForm"
        style={{ width: "100%" }}
        onFinish={handleFinish}
        initialValues={{...form, 
          country: 1
        }}
      >
        <Row gutter={8} >
            <Col span={12}>
              <Form.Item
                label="Agent Name"
                name="agentName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Agent Name",
                  },
                ]}
              >
                <Input placeholder="Agent Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Distributor Name"
                name="distributorId"
                rules={[
                  {
                    required: true,
                    message: "Select Distributor",
                  },
                ]}
              >
                <Select
                  placeholder="Distributor"
                  onSearch={(input) => setSearch(input)}
                  showSearch={true}
                  filterOption={false}
                  listHeight={150}
                >
                  {
                    filterDistributors(search).map((e,i) => <Select.Option key={i} value={e.value} 
                      disabled={e.status === 1 ? false : true}>{e.label}</Select.Option>)
                  }
                </Select> 
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="agentEmail"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email",
                  },
                  {
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please Enter Valid Email",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Business Phone"
                name="businessPhone"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Phone Number",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please Enter Valid Phone Number",
                  },
                ]}
              >
                <Input
                  placeholder="Business Phone"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Select Country",
                  },
                ]}
              >
                <Select
                  placeholder="Country"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                  showSearch={true}
                  disabled
                  options={countries}
                  onChange={(value) => (
                    handleGetStates(value),
                    form.setFieldsValue({
                      state: null,
                      city: null,
                    }),
                    setCities([])
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: "Select State",
                  },
                ]}
              >
                <Select
                  placeholder="State"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                  showSearch={true}
                  options={states}
                  onChange={(value) => (
                    handleGetCities(value),
                    form.setFieldsValue({
                      city: null,
                    })
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Select City",
                  },
                ]}
              >
                <Select
                  placeholder="City"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                  showSearch={true}
                  options={cities}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Street Address"
                name="streetAddress"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Address",
                  },
                ]}
              >
                <Input
                  placeholder="Street Address"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Zip Code"
                name="zipcode"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Zip Code",
                  },
                  {
                    pattern: /^[0-9]{5}$/,
                    message: "Please Enter Valid Zip Code",
                  },
                ]}
              >
                <Input
                  placeholder="Zip Code"
                />
              </Form.Item>
            </Col>
        </Row>
        <div className="text-right mb-1">
          <Button
            type="primary"
            className="mr-3 btn--large"
            onClick={()=>history.goBack()}
          >
            Back
          </Button>
          <Button
            type="warning"
            htmlType="submit"
            className="btn--large"
          >
            { loader ? <SupportLoader/> : "Submit"}
          </Button>
        </div>
      </Form>        
    </div>
    </>
  )
}

export default AddAgent