import React from 'react';
import { Button } from 'antd';
import Pagetitle from '../Pagetitle'
import { Layout, Row, Col, Space, Spin, message, Alert, notification, Result } from 'antd';
import { motion } from "framer-motion";
const title = "All Loader & Message";
const { Content } = Layout;
const icon = {
    hidden: {
        opacity: 1,
        pathLength: 0,
    },
    visible: {
        opacity: 1,
        pathLength: 1,
    }
};
const Allloader = () => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [spinLoading, setSpinLoading] = React.useState(false);
    const showbuttonLoading = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setConfirmLoading(false);
        }, 2000);
    }
    const showspinLoader = () => {
        setSpinLoading(true);
        setTimeout(() => {
            setSpinLoading(false);
        }, 2000);
    }
    const success = () => {
        message.success('This is a success message');
    };

    const error = () => {
        message.error('This is an error message');
    };

    const warning = () => {
        message.warning('This is a warning message');
    };
    const info = () => {
        message.info('This is a info message');
    };

    const openNotificationWithIcon = type => {
        notification[type]({
            message: 'Notification Title',
            description:
                'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        });
    };

    return (
        <>
            <Pagetitle title={title} />
            <Spin spinning={spinLoading}>
                <Content className="content-body" style={{ minHeight: 500 }}>
                    <Row>
                        <Col lg={24} xs={24}>
                            <div style={{ margin: '0px 14px 14px 14px' }}>
                                <h3>Button Loading</h3>
                                <Space direction="vertical">
                                    <Button type="primary" loading={confirmLoading} onClick={showbuttonLoading}>
                                        Click me! (Show Button Loading)
                                    </Button>
                                </Space>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24} xs={24}>
                            <div style={{ margin: '0px 14px 14px 14px' }}>
                                <h3>Process Loader</h3>
                                <Space direction="vertical">
                                    <Button type="warning" onClick={showspinLoader}>
                                        Click me! (Show Loader)
                                    </Button>
                                </Space>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24} xs={24}>
                            <div style={{ margin: '0px 14px 14px 14px' }}>
                                <h3>Message</h3>
                                <Space>
                                    <Button ghost type="success" onClick={success}>Success</Button>
                                    <Button ghost type="danger" onClick={error}>Error</Button>
                                    <Button ghost type="warning" onClick={warning}>Warning</Button>
                                    <Button ghost type="info" onClick={info}>Info</Button>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24} xs={24}>
                            <div style={{ margin: '0px 14px 14px 14px' }}>
                                <h3>Alert</h3>
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <Alert message="Success" banner type="success" showIcon closable />
                                    <Alert message="Info" banner type="info" showIcon closable />
                                    <Alert message="Warning" banner type="warning" showIcon closable />
                                    <Alert message="Error" banner type="error" showIcon closable />
                                </Space>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24} xs={24}>
                            <div style={{ margin: '0px 14px 14px 14px' }}>
                                <h3>Notification</h3>
                                <Space>
                                    <Button type="success" onClick={() => openNotificationWithIcon('success')}>Success</Button>
                                    <Button type="info" onClick={() => openNotificationWithIcon('info')}>Info</Button>
                                    <Button type="warning" onClick={() => openNotificationWithIcon('warning')}>Warning</Button>
                                    <Button type="danger" onClick={() => openNotificationWithIcon('error')}>Error</Button>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24} xs={24}>
                            <div style={{ margin: '0px 14px 14px 14px' }}>
                                <h3>404</h3>
                                <Result
                                    status="404"
                                    title="404"
                                    subTitle="Sorry, the page you visited does not exist."
                                    extra={<Button type="primary">Back Home</Button>}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="pageLoader">
                        <div className="loaderContainer">
                            <motion.svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px" y="0px"
                                width="185.833px" height="140.667px" viewBox="0 0 185.833 260.667" enable-background="new 0 0 185.833 170.667"
                                className="loaderItem"
                            >
                                <motion.path
                                    id="Path_3364" fill="#21649C" d="M110.128,57.04v26.023h45.104v16.08H90.113V2h64.977v16.229h-44.961V57.04z"
                                    variants={icon}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{
                                        default: { duration: 2,repeat: Infinity, ease: "easeInOut" },
                                        fill: { duration: 2, repeat: Infinity, ease: [1, 0, 0.8, 1] }
                                    }}
                                />
                                <motion.path
                                    id="Path_3371" fill="#FFDA61" d="M157.826,112.25l-101.034-2.074L31.903,71.958c0,0-8.297-11.554,11.261-11.848
      c9.899-0.15,102.8-0.292,110.847-0.389V43.968l-40.907-0.173l0,0H20.051c0,0-20.738-1.478-17.758,22.222
      c2.979,23.699,33.165,62.827,40.871,67.564c112.289,6.517,116.736,7.408,116.736,7.408L157.826,112.25z"
                                    variants={icon}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{
                                        default: { duration: 2,repeat: Infinity, ease: "easeInOut" },
                                        fill: { duration: 2, repeat: Infinity,ease: [1, 0, 0.8, 1] }
                                    }}
                                />
                                <motion.circle
                                    id="Ellipse_1" fill="#21649C" cx="89.672" cy="159.51" r="9.184"
                                    variants={icon}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{
                                        default: { duration: 2,repeat: Infinity, ease: "easeInOut" },
                                        fill: { duration: 2, repeat: Infinity,ease: [1, 0, 0.8, 1] }
                                    }}
                                />
                                <motion.circle
                                    id="Ellipse_2" fill="#21649C" cx="129.968" cy="159.51" r="9.184"
                                    variants={icon}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{
                                        default: { duration: 2,repeat: Infinity, ease: "easeInOut" },
                                        fill: { duration: 2, repeat: Infinity,ease: [1, 0, 0.8, 1] }
                                    }}
                                />
                                <g id="Group_5246" transform="translate(41.429 44.695)">
                                    <motion.path id="Path_3372" fill="#F9D341" d="M104.15,107.16v2.958h34.372l4.741-2.961L104.15,107.16z" variants={icon}
                                        initial="hidden"
                                        animate="visible"
                                        transition={{
                                            default: { duration: 2,repeat: Infinity, ease: "easeInOut" },
                                            fill: { duration: 2,repeat: Infinity, ease: [1, 0, 0.8, 1] }
                                        }} />
                                    <motion.path id="Path_3373" fill="#F9D341" d="M104.15,117.233h17.314l2.39-2.963H104.15V117.233z" variants={icon}
                                        initial="hidden"
                                        animate="visible"
                                        transition={{
                                            default: { duration: 2, repeat: Infinity,ease: "easeInOut" },
                                            fill: { duration: 2, repeat: Infinity,ease: [1, 0, 0.8, 1] }
                                        }} />
                                </g>
                            </motion.svg>
                        </div>
                    </div>

                </Content>
            </Spin>
        </>
    );
}
export default Allloader;