import React, { useCallback, useEffect, useState , } from 'react'
import PaginatedTable from '../../../common/PaginatedTable';
import { Link, useHistory } from "react-router-dom";
import {
    EyeOutlined
} from "@ant-design/icons";
import AxiosService from '../../../../utils/Config/ApiService';
import SupportLoader from '../../../../utils/Config/SupportLoader';
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';

export const Receipt = ({storeid}) => {
    const[isloader, setisloader] = useState(true)
    const [pageloader, setpageloader] = useState(undefined)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    let [totalCount, setTotalCount] = useState(0)
    let [currentPage, setCurrentPage] = useState(1)
    let [itemsPerPage, setItemsPerPage] = useState(10)
    const history = useHistory()
    let [searchKey, setSearchKey] = useState("")

    let [isLoading, setIsLoading] = useState(true)
    let [users, setUsers] = useState([
        { ReceiptNo: '12456', PaidAmount: '$0.10', PaymentType: 'Cash' },
        { ReceiptNo: '12456', PaidAmount: '$0.10', PaymentType: 'Cash' },
    ]);
    let [receipts, setreceipts] = useState([])

    const getStoreReceiptsList = useCallback(async()=>{
        try{
            let res = await AxiosService.get(`${ApiRoutes.STORE_RECEIPTS.service}${ApiRoutes.STORE_RECEIPTS.url}/${storeid}`,{
              params : {
                pgno : currentPage,
                limit : itemsPerPage,
                searchKey : searchKey
            }
            })
            if(res.data.statusCode === 200){
          
                setreceipts(res.data.data)
                setTotalCount(res.data.count)
                setIsLoading(true)
                setisloader(false)
                setpageloader(false)
            }
        }catch(error){
            console.log(error)
        }
    },[currentPage, itemsPerPage, searchKey,storeid])
    

    //useState is required if we need column filter
    const [column, setColumn] = useState([
        {
            heading: () => "Receipt No",
            cell: row => row['invoiceNo'],
            hidden: false,
            width: "100",
            id: 2
        },
        {
            heading: () => "Paid Amount",
            cell: row =>row['payableAmount'].includes("-") ? "-" + " "+ row['currencySign'] +" "+ row['payableAmount'].replace("-","")  : row['currencySign'] + " " + row['payableAmount'], //prefix - and then $ for -ve numbers
            hidden: false,
            width: "100",
            id: 3
        },
        {
            heading: () => "Payment Type",
            cell: row => row['paymentType'],
            hidden: false,
            width: "100",
            id: 4
        },

        {
            heading: () => "Actions",
            cell: row => {
                return <><Link to= {`/support/store/receipt/${row.pointepayOrderId}`}
                 className="btn--small btn--view" ><EyeOutlined /></Link> </>
            },
            hidden: false,
            width: "20",
            id: 14
        }

    ])
    //required to get the filtered column
    let filterOptions = []
    //default checked values of select options
    let filterDefaultValue = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 13 }, { value: 14 }]

    //generate the options of multi select
    column.forEach((e) => {
        let option = {
            label: e.id === 13 ? 'Status' : e.heading(),
            value: e.id
        }
        filterOptions.push(option)
    })

    //to handle the change of the react-multi select
    const handleFilterSelection = (options) => {
        let newColumn = [...column]
        for (let i = 0; i < newColumn.length; i++) {
            let data = options.filter((e) => newColumn[i].id === e.value)
            data.length === 1 ? newColumn[i].hidden = false : newColumn[i].hidden = true
        }
        setColumn(newColumn)
    }
    useEffect(() =>{
        getStoreReceiptsList()
    }, [currentPage, itemsPerPage, searchKey, storeid])
    return (
        <>
            {!isloader ? <PaginatedTable
                row={receipts}
                column={column}
                totalCount={totalCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                isFilterHeader={true}
                filterOptions={filterOptions}
                handleFilterSelection={handleFilterSelection}
                filterDefaultValue={filterDefaultValue}
                isMultiSelect= {false}
                isSearch={true}
                maxPageNumberLimit={maxPageNumberLimit}
                setMaxPageNumberLimit={setMaxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                setMinPageNumberLimit={setMinPageNumberLimit}
                setpageloader={setpageloader}
                pageloader={pageloader}
            /> : <SupportLoader/>}
        </>
    )
}
