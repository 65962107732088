import { EnvironmentOutlined, FlagOutlined, IdcardOutlined, LoginOutlined, MailOutlined, PhoneOutlined, ShareAltOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Row, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';
import AxiosService from '../../../../utils/Config/ApiService';
import QRCode from "react-qr-code"
import { Spinner } from 'react-bootstrap';

const ViewDistributor = () => {

  const history = useHistory();
  const params = useParams();

  const [messageApi, contextHolder] = message.useMessage();

  const [distributorDetail, setDistributorDetail] = useState(null)

  const getDistributorDetails = async () => {
    try{
      const response = await AxiosService.get(`${ApiRoutes.DISTRIBUTOR_DETAIL.service}${ApiRoutes.DISTRIBUTOR_DETAIL.url}${params.id}`);
      if(response.status === 200){
        setDistributorDetail(response.data.distributorDetails)
      }
    }catch(error){
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.message ?? error.response.data.error
      })
    }
  }

  useEffect(()=>{
    getDistributorDetails();
  },[])

  return (
    <>
      {contextHolder}
      <div className="text-right mb-1">
        <Button
          type="primary"
          className="mr-3 btn--large"
          onClick={()=>history.push(`/distributor/distributorAdmin`)}
        >
          Back
        </Button>
        <Button
          type="warning"
          className="btn--large"
          onClick={()=>history.push(`/distributor/distributorAdmin/edit/${params?.id}`)}
        >
          Edit
        </Button>
      </div>
      <Row gutter={8} className="mb-05">
        <Col span={8}>
          <div className="profileDetailsLeft bg-white" style={{ display: 'flex', justifyContent: "center"}}>
          {
            distributorDetail?.distributorUniqueId ? 
            <QRCode value={process.env.REACT_APP_ROOT_URL + 
              `/distributor?id=${distributorDetail?.distributorUniqueId}` + 
              `&name=${distributorDetail?.businessName}`}/> :
            <Spinner/>  
          }
          </div>
        </Col>
        <Col span={16}>
          <div className="profileDetailsRight bg-white">
            <Row gutter={8}>
            <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <IdcardOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Distributor Digital ID</p>
                    <p>{distributorDetail?.distributorUniqueId}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <ShopOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Business Name</p>
                    <p>{distributorDetail?.businessName}</p>
                  </div>
                </div>
              </Col>

              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <PhoneOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Phone</p>
                    <p>
                      {distributorDetail?.businessPhoneCode}{distributorDetail?.businessPhone}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <FlagOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">
                      Country / State / City Name
                    </p>
                    <p>
                      {distributorDetail?.countryName} / {distributorDetail?.stateName} / {distributorDetail?.cityName}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <UserOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Contact Name</p>
                    <p>
                      {distributorDetail?.contactName}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <MailOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Email</p>
                    <p>{distributorDetail?.email}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <EnvironmentOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Address</p>
                    <p>{distributorDetail?.streetAddress}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <LoginOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Status</p>
                    <p>{distributorDetail?.status === 1 ? "Active" : "Inactive"}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <EnvironmentOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Zipcode</p>
                    <p>{distributorDetail?.zipcode}</p>
                  </div>
                </div>
              </Col>
              { distributorDetail?.isAgent === 1 &&
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <ShareAltOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Agent</p>
                  </div>
                </div>
              </Col>
              }
            </Row>
          </div>
        </Col>
      </Row>      
    </>
  )
}

export default ViewDistributor