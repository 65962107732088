import React from "react";
import { Link } from "react-router-dom";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Layout as Layouts, Switch } from "antd";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import Footerlayout from "./footer/index";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Walleticon from "../../assets/images/Wallet/wallet-icon.png";
import { getUserData } from "../../utils/Helpers";
import { AppConfig } from "../../utils/Config/AppConfig";
import { ApiRoutes } from "../../utils/Config/ApiRoutes";
import { ApiHelper } from "../../utils/Helpers";
import { messages } from "../../utils/Common/Messages";

const Layout = (props) => {
  const lan = getUserData().language ?? "EN";
  const defaultCurrency = getUserData().currencySign;

  const { setIsDarkMode, isDarkMode } = props;
  const { itu, organizationTypeId } = getUserData();
  const { Header } = Layouts;
  const history = useHistory();
  //const { Header, Sider } = Layouts;
  //  const [isDarkMode, setIsDarkMode] = React.useState();
  //const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [walletAmount, setWelletAmount] = React.useState();
  const { switcher, themes } = useThemeSwitcher();
  const toggleTheme = (isChecked) => {
    setIsDarkMode(isChecked);
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };
  const url =
    organizationTypeId === 1 || organizationTypeId === "1"
      ? AppConfig.RETAILERURL
      : AppConfig.SUPERMERCHANTURL;

  const getWalletBalance = async (userData) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_WALLET_BAL.service,
      ApiRoutes.GET_WALLET_BAL.url,
      ApiRoutes.GET_WALLET_BAL.method,
      ApiRoutes.GET_WALLET_BAL.authenticate,
      undefined,
      {
        parentOrganizationId: userData?.parentOrganizationId,
        organizationId: userData?.organizationId,
        organizationTypeId: getUserData().organizationTypeId,
      }
    );
    if (response.code === 200) {
      setWelletAmount(response.data?.response?.data?.walletAmount || 0.0);
    } else {
      setWelletAmount(0.0);
    }
  };

  React.useEffect(() => {
    const userData = getUserData();
    if (userData === undefined || userData === "") {
      window.location.href = AppConfig.ROOTURL;
      // } else if (userData.organizationTypeId === 1) {
      //   //setUrl(AppConfig.RETAILERURL);
      // } else if (
      //   userData.organizationTypeId === 2 &&
      //   userData.metaKey === "MERCHANT_STORE_TYPE"
      // ) {
      //   // setUrl(AppConfig.SUPERMERCHANTURL);
      // } else if (userData.organizationTypeId === 2) {
      //   //setUrl(AppConfig.SUPERMERCHANTURL);
      // }
    } else {
      getWalletBalance(userData);
    }
  }, []);
  return (
    <>
      <div className="ekiakrtApp" id="ekikartLayout">
        <Layouts style={{ minHeight: "100vh" }}>
          {/*<MediaQuery minDeviceWidth={992}>
            <Sider
              trigger={null}
              collapsible
              collapsed={isCollapsed}
              width={230}
              className="ekikartSidebar"
            >
              <Sidebarlayout />
            </Sider>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={991}>
            <Sider
              trigger={null}
              collapsible
              collapsed={!isCollapsed}
              width={230}
              className="ekikartSidebar"
            >
              <Sidebarlayout />
            </Sider>
          </MediaQuery>*/}
          <Layouts className="site-layout">
            <Header className="bg-white p-0 horizontalMenuOnlyHeader">
              {/*{React.createElement(
                isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: () => setIsCollapsed(!isCollapsed),
                }
              )}*/}
              {/* <button
                onClick={() => history.goBack()}
                type="secondary"
                className="ant-btn ant-btn-secondary float-left"
              >
                <ArrowLeftOutlined /> Back
              </button> */}
              <span className="mr-auto">
                {history?.location?.pathname === "/" ? (
                  <a href={url}>
                    <Button
                      type="primary"
                      onClick={() => history.goBack()}
                      icon={<ArrowLeftOutlined />}
                      className="m-10"
                    />
                  </a>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => history.goBack()}
                    icon={<ArrowLeftOutlined />}
                    className="m-10"
                  />
                )}
              </span>
              {walletAmount !== undefined &&
              (itu === "true" || itu === true) ? (
                <Link to="/wallet" className="walletAmountBox">
                  <div className="walletIcon mr-1">
                    <img src={Walleticon} alt="Wallet Icon" width="38" />
                  </div>
                  <div className="walletAmount">
                    <h5>{messages.balance[lan]}</h5>
                    <h4 className="amountText">
                      {defaultCurrency}
                      {Number(
                        parseFloat(walletAmount).toFixed(2)
                      ).toLocaleString(
                        undefined, // leave undefined to use the visitor's browser
                        // locale or a string like 'en-US' to override it.
                        { minimumFractionDigits: 2 }
                      )}
                    </h4>
                  </div>
                </Link>
              ) : (
                ""
              )}
              <Switch
                checked={isDarkMode}
                onChange={toggleTheme}
                className="ant-dropdown-link float-right m-10"
              />
              {/*<Headerlayout {...props} />*/}
            </Header>
            <>{props.children}</>
            <Footerlayout />
          </Layouts>
        </Layouts>
      </div>
    </>
  );
};

export default Layout;
