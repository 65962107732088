import React, { useEffect, useLayoutEffect,useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getUserData } from "../../../../utils/Helpers";

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;
am4core.addLicense("ch-custom-attribution");

const Itemschart = (props) => {
  const [defaultCurrency,setdefaultCurrency] = useState(getUserData().currencySign);
  // let allData = "";
  //let month = "";
  useLayoutEffect(() => {
    let allData = props.productSaleData.allData;
    let month = props.productSaleData.month;
    let chart = "";
    chart = am4core.create("itemSalesChart", am4charts.XYChart);
    chart.padding(0, 5, 0, 0);

    chart.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "M" },
      { number: 1e9, suffix: "B" },
    ];

    let label = chart.plotContainer.createChild(am4core.Label);
    label.x = am4core.percent(97);
    label.y = am4core.percent(95);
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";
    label.dx = 0;
    label.dy = 10;
    label.fontSize = 25;
    label.fill = am4core.color("#555555");

    let playButton = chart.plotContainer.createChild(am4core.PlayButton);
    playButton.x = am4core.percent(97);
    playButton.y = am4core.percent(95);
    playButton.dy = 8;
    playButton.dx = 9;
    playButton.verticalCenter = "middle";
    playButton.events.on("toggled", function (event) {
      if (event.target.isActive) {
        play();
      } else {
        stop();
      }
    });

    let stepDuration = 4000;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "items";
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fill = am4core.color("#959595");

    let labely = categoryAxis.renderer.labels.template;
    labely.truncate = true;
    labely.maxWidth = 170;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.rangeChangeEasing = am4core.ease.linear;
    valueAxis.rangeChangeDuration = stepDuration;
    valueAxis.extraMax = 0.1;
    valueAxis.renderer.labels.template.fill = am4core.color("#959595");
    chart.series.clear();
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "items";
    series.dataFields.valueX = props.productType;
    series.tooltipText = "{valueX.value}";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusBottomRight = 0;
    series.columns.template.column.cornerRadiusTopRight = 0;
    series.interpolationDuration = stepDuration;
    series.interpolationEasing = am4core.ease.linear;

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.horizontalCenter = "right";

    labelBullet.label.text =
      props.productType === "amount"
        ? "{values.valueX.workingValue.formatNumber('" +
          defaultCurrency +
          "#.00')}"
        : "{values.valueX.workingValue.formatNumber('#.0')}";
    labelBullet.label.textAlign = "end";
    labelBullet.label.dx = -10;
    labelBullet.label.fill = "#ffffff";

    chart.zoomOutButton.disabled = true;

    series.columns.template.adapter.add("fill", function (fill, target) {
      return (chart.colors.fill = am4core.color("#30649B"));
    });

    let year = 1;
    // month = ["06/06", "07/06", "08/06"];

    label.text = month ? (month[0] ? month[0].toString() : month[0]) : "";

    let interval;

    function play() {
      interval = setInterval(function () {
        nextYear();
      }, stepDuration);
      nextYear();
    }

    function stop() {
      if (interval) {
        clearInterval(interval);
      }
    }

    function nextYear() {
      year++;

      if (year > month.length) {
        year = 1;
      }
      let newData = allData[year];

      let itemsWithNonZero = 0;
      for (var i = 0; i < chart.data.length; i++) {
        if (props.productType === "amount") {
          chart.data[i].amount = parseFloat(newData[i].amount).toFixed(2);
          if (chart.data[i].amount > 0) {
            itemsWithNonZero++;
          }
        } else {
          chart.data[i].quantity = parseFloat(newData[i].quantity);
          if (chart.data[i].quantity > 0) {
            itemsWithNonZero++;
          }
        }
      }

      if (year === 1) {
        series.interpolationDuration = stepDuration / 4;
        valueAxis.rangeChangeDuration = stepDuration / 4;
      } else {
        series.interpolationDuration = stepDuration;
        valueAxis.rangeChangeDuration = stepDuration;
      }

      chart.invalidateRawData();

      label.text = month[year - 1] ? month[year - 1].toString() : "";

      categoryAxis.zoom({
        start: 0,
        end: itemsWithNonZero / categoryAxis.dataItems.length,
      });
    }

    categoryAxis.sortBySeries = series;

    chart.data = JSON.parse(JSON.stringify(allData[year]));

    categoryAxis.zoom({ start: 0, end: 1 / chart.data.length });

    series.events.on("inited", function () {
      // setTimeout(function () {
      playButton.isActive = true; // this starts interval
      // }, 2000);
    });

    chart.current = chart;
    //this.chart = chart;
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      chart.dispose();
      // am4core.disposeAllCharts();
    };
  }, [
    props.productSaleData.allData,
    props.productSaleData.month,
    props.productType,
  ]);

  useEffect(()=>{
    setdefaultCurrency(getUserData().currencySign)
  },[])

  // Change Height Later, `${250 * (props.productLimit / 5)}px`
  return <>
    {
    defaultCurrency!==undefined?<div
      id="itemSalesChart"
      style={{ width: "100%", height: `${250}px` }}
    ></div>:<></>
    }
  </>
};

export default Itemschart;
