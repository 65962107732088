import React,{useEffect,useState} from 'react'
import { ApiHelper,getUserData } from '../../../../utils/Helpers'
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import { messages } from "../../../../utils/Common/Messages";
import { message } from 'antd';
function GiftCardCount() {
    const [totalConsumed,setTotalConsumed] = useState(0)
    const [totalActive,setTotalActive] = useState(0)
    const [totalInactive,setTotalInactive] = useState(0)
    const [totalRows,setTotalRows] = useState(0)
    const userData = getUserData();
    const lan = userData.language ?? "EN";
    const organizationId = userData.organizationTypeId===1?userData.parentOrganizationId:userData.organizationId

    const getGiftCardCount = async()=>{
        try {
            let response = await new ApiHelper().FetchFromServer(
                ApiRoutes.GIFT_CARD_COUNT.service,
                ApiRoutes.GIFT_CARD_COUNT.url+`?organizationId=${organizationId*55}`,
                ApiRoutes.GIFT_CARD_COUNT.method,
                ApiRoutes.GIFT_CARD_COUNT.authenticate,
            )
            if(response.code===200)
            {
                setTotalConsumed(response.data.data.totalConsumed)
                setTotalActive(response.data.data.totalActive)
                setTotalInactive(response.data.data.totalInactive)
                setTotalRows(response.data.data.totalRows)
            }
            else
            {
                message.error({
                    content:"Error Occoured",
                    duration:2
                })
            }
        } catch (error) {
            message.error({
                content:error.message,
                duration:2
            })
        }
    }

    useEffect(()=>{
        getGiftCardCount()
    },[])

  return (
    <div className='d-flex'>
        <div className="mLR10 fs-15">
            {messages.totalConsumed[lan]} : {totalConsumed}
        </div>
        <div className="mLR10 fs-15">
            {messages.totalActive[lan]} : {totalActive}
        </div>
        <div className="mLR10 fs-15">
            {messages.totalInactive[lan]} : {totalInactive}
        </div>
        <div className="mLR10 fs-15">
            {messages.totalCards[lan]} : {totalRows}
        </div>
    </div>
  )
}

export default GiftCardCount