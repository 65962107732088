import { Button, Col, Row, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';
import AxiosService from '../../../../utils/Config/ApiService';
import { CalendarOutlined, UserOutlined, LoginOutlined, IdcardOutlined } from '@ant-design/icons'
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import { Spinner } from 'react-bootstrap';
import '../agent/distributor.less';
import { DownloadOutlined } from "@ant-design/icons";

const ViewCampaign = () => {

  const history = useHistory();
  const params = useParams();

  const [messageApi, contextHolder] = message.useMessage();

  const [campaignDetail, setCampaignDetail] = useState(null)

  const getCampaignDetails = async () => {
    try {
      const response = await AxiosService.get(`${ApiRoutes.CAMPAIGN_DETAIL.service}${ApiRoutes.CAMPAIGN_DETAIL.url}${params.id}`);
      if (response.status === 200) {
        setCampaignDetail(response.data.campaignDetails)
      }
    } catch (error) {
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.error ?? error.response.data.message
      })
    }
  }

  function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  }

  const downloadQrcode = async () => {
    let body = document.getElementById("Qrcode")
    const canvas = await html2canvas(body);
    var myImage = canvas.toDataURL("image/png");
    downloadURI("data:" + myImage, `${campaignDetail?.campaignUniqueId}.png`);
  }

  useEffect(() => {
    getCampaignDetails();
  }, [])

  return (
    <>
      {contextHolder}
      <div className="text-right mb-1">
        <Button
          type="primary"
          className="mr-3 btn--large"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
        <Button
          type="warning"
          className="btn--large"
          onClick={() => history.push(`/distributor/campaignAdmin/edit/${params?.id}`)}
        >
          Edit
        </Button>
      </div>
      <Row gutter={8} className="mb-05">
        <Col span={8}>
          <div className="profileDetailsLeft bg-white d-flex justify-content-center alignCenter p-0" id='Qrcode' >
            {campaignDetail?.campaignUniqueId ?
              <div className='qrCodeContainer w-100 h-100 py-3'>
                <div className='qrCode  h-100 d-flex justify-content-center alignCenter'>
                  <QRCode value={process.env.REACT_APP_ROOT_URL +
                    `/leads/create?id=${campaignDetail?.campaignUniqueId}`}
                  /></div>
                <div class="qrCodeDownload">
                  <div className='icon cursor-pointer' onClick={() => downloadQrcode()}>
                    <DownloadOutlined />
                  </div>
                </div>
              </div> :
              <Spinner />
            }
          </div>
        </Col>
        <Col span={16}>
          <div className="profileDetailsRight bg-white">
            <Row gutter={8}>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <IdcardOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Campaign Digital ID</p>
                    <p>{campaignDetail?.campaignUniqueId}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <UserOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Campaign Name</p>
                    <p>{campaignDetail?.campaignName}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <UserOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Distributor Name</p>
                    <p>
                      {campaignDetail?.distributorName}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <CalendarOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Start Date</p>
                    <p>{campaignDetail?.startDate}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <CalendarOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">End Date</p>
                    <p>{campaignDetail?.endDate}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <LoginOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Status</p>
                    <p>{campaignDetail?.status === 1 ? "Active" : "Inactive"}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ViewCampaign