import React from "react";
import { Table } from "antd";
import { messages } from "../../../../utils/Common/Messages";
import { getUserData } from "../../../../utils/Helpers";

const NonRevenueSummarylist = (props) => {
  const dualPricing = getUserData()?.dualPricing ?? false;
  const { interval, dataSetSummaryReport, lan, currencySign, dataSetTaxSummaryReport, dataSetVoidSummaryReport } = props;
  
  console.log('DataSummaryReport',dataSetSummaryReport);
  const columns = [
    {
      title: messages.sales[lan],
      dataIndex: "label",
      key: "label",
      fixed: "left",
      width: 170,
    },
  ];
  const data = [];

 
  let serviceUsageCount = {
    key: "serviceUsageCount",
    label: "Total Non-Revenue Services"
  }

  // let inventoryImpact = {
  //   key: "inventoryImpact",
  //   label: "inventory Impact"
  // }
  let mostFrequentNonRevenueItem = {
    key: "mostFrequentNonRevenueItem",
    label: "Most Frequent Non-Revenue Item"
  }

  let totalNonRevenueItemsProcessed = {
    key: "totalNonRevenueItemsProcessed",
    label: "Total Non-Revenue Products"
  }
  let children = [];
  let keyChildren = [];
  // if (dataSetTaxSummaryReport && dataSetTaxSummaryReport.length > 0) {
  //   dataSetTaxSummaryReport.forEach((value) => {
  //     console.log('value', value);
  //     if (keyChildren.indexOf(value.key) < 0) {
  //       // keyChildren.push(value.key);
  //       children.push({
  //         key: value.taxName,
  //         label: value.taxName
  //       });
  //     }

  //   });
  // }

  let voidChildren = [];
  let voidkeyChildren = [];
  // if (dataSetVoidSummaryReport && dataSetVoidSummaryReport.length > 0) {
  //   dataSetVoidSummaryReport.forEach((value) => {
  //     console.log('value', value);
  //     if (voidkeyChildren.indexOf(value.key) < 0) {
  //       voidChildren.push({
  //         key: value.voidType,
  //         label: value.voidType
  //       });
  //     }

  //   });
  // }
  if (dataSetSummaryReport && dataSetSummaryReport.length > 0) {
    for (let i = 0; i < dataSetSummaryReport.length; i++) {
      columns.push({
        title:
          dataSetSummaryReport[i].interval_start !==
            dataSetSummaryReport[i].interval_end
            ? dataSetSummaryReport[i].interval_start +
            "-" +
            dataSetSummaryReport[i].interval_end
            : dataSetSummaryReport[i].interval_start,
        dataIndex: `dayfilterData${i + 1}`,
        key: i + 1,
        width: 150,
        align: "right",
      });

    

            totalNonRevenueItemsProcessed[`dayfilterData${i + 1}`] =  dataSetSummaryReport[i].totalNonRevenueProductProcessed
             
              mostFrequentNonRevenueItem[`dayfilterData${i + 1}`] =  dataSetSummaryReport[i].mostFrequentNonRevenueItem
              serviceUsageCount[`dayfilterData${i + 1}`] =  dataSetSummaryReport[i].serviceUsageCount
            
              
            //  inventoryImpact[`dayfilterData${i + 1}`] = dataSetSummaryReport[i].inventoryImpact;
               
                
        

    }
  }


 
  data.push(mostFrequentNonRevenueItem);
  data.push(totalNonRevenueItemsProcessed);

  // data.push(inventoryImpact);
  data.push(serviceUsageCount);
  // if(dualPricing==="true" || dualPricing===true)
  //   data.push(cashDiscount);

  console.log(serviceUsageCount);
  return (
    <Table
      locale={{ emptyText: messages.noData[lan] }}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={interval === "summary" || columns.length < 3 ? "" : { x: 1500 }}
      className="paymentcollectedTable"
    />
  );
};
export default NonRevenueSummarylist;
