import React from "react";
//import MediaQuery from "react-responsive";
import { Layout as Layouts } from "antd";

const WithHeaderFooterLayout = (props) => {
  return (
    <>
      <div className="ekiakrtApp" id="ekikartLayout">
        <Layouts style={{ minHeight: "100vh" }}>
          <>{props.children}</>
        </Layouts>
      </div>
    </>
  );
};

export default WithHeaderFooterLayout;
