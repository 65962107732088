import {
  Button,
  Col,
  Row,
  Form,
  Input,
  Select,
  Checkbox,
  message,
  Upload,
  AutoComplete,
} from "antd";

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  LoadingOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import AxiosService from "../../../../../utils/Config/ApiService";
import { useFormik } from "formik";
import * as Yup from "yup";
import SupportLoader from "../../../../../utils/Config/SupportLoader";
import { ApiRoutes } from "../../../../../utils/Config/ApiRoutes";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const MerchantEditProfile = () => {
  //roles
  const [makesale, setmakesale] = useState(false);
  const [showDis, setshowDis] = useState(false);
  const [designation, setDesignation] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  // const [empRolesArr, setempRolesArr] = useState([])
  const [loading, setLoading] = useState(false);
  const [countries, setcountries] = useState([]);
  const [allcountries, setallcountries] = useState([]);
  const [states, setstates] = useState([]);
  const [constStates, setConstStates] = useState([]);
  const [constCities, setConstCities] = useState([]);
  const [constLocalArea, setConstLocalAreas] = useState([]);
  const [constCountries, setconstCountries] = useState([]);
  const [cities, setcities] = useState([]);
  const [localAreas, setlocalareas] = useState([]);
  const [staff, setstaff] = useState({});
  const [imageUrl, setImageUrl] = useState();
  const [isloader, setisloader] = useState(true);
  const [subloader, setsubloader] = useState(false);
  const [roleList, setroleList] = useState([]);
  const [empRoles, setempRoles] = useState([]);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const updatedBy = userData.employeeId;
  const staffId = useParams()?.id;
  const history = useHistory();
  const { Option } = AutoComplete;
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleGetDesignation = async () => {
    try {
      let res = await AxiosService.post(`${ApiRoutes.STAFF_DESIGNATION.service}${ApiRoutes.STAFF_DESIGNATION.url}`, {
        empRoles: empRoles,
        employeeId: staffId,
      });

      if (res.status === 200) {
        setDesignation(res.data.designation);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyRoleCount = () => {
    const empRolesArr = [...empRoles];
    const fifteenthVal = empRolesArr.indexOf(15);
    if (fifteenthVal > -1) {
      empRolesArr.splice(fifteenthVal, 1);
    }

    const nineteenthVal = empRolesArr.indexOf(19);
    if (nineteenthVal > -1) {
      empRolesArr.splice(nineteenthVal, 1);
    }

    return empRolesArr.length;
  };

  const handleEditProfile = async (values) => {
    try {
      if (verifyRoleCount() > 0) {
        if (designation !== "") {
          let data = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
            businessPhone: values?.businessPhone ? values?.businessPhone : "",
            businessPhoneCode: staff?.businessPhoneCode,
            // userName : staff?.userName,
            country:
              typeof values?.countryName === "string"
                ? staff?.country
                : values?.countryName,
            state:
              typeof values?.stateName === "string"
                ? staff?.state
                : values?.stateName,
            city:
              typeof values?.cityName === "string"
                ? staff?.city
                : values?.cityName,
            zip: values?.zip ? values?.zip : "",
            addressLine1: values?.addressLine1 ? values?.addressLine1 : "",
            isAllowDiscount: staff?.isAllowDiscount,
            saleItemPermission: staff?.saleItemPermission,
            localArea: staff?.localArea,
            designation: designation,
            updatedBy: updatedBy,
            empRoles: empRoles,
          };
          let res = await AxiosService.put(`${ApiRoutes.STAFF.service}${ApiRoutes.STAFF.url}/${staffId}`, data);
          if (res.status === 200) {
            setsubloader(false);
            history.goBack();
          }
        } else {
          alert("Please add designation");
          setsubloader(false);
        }
      } else {
        alert("Please select aleast one role");
        setsubloader(false);
      }
    } catch (error) {
      console.log(error);
      setsubloader(false);
    }
  };
  const handleGetCountries = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.COUNTRY.service}${ApiRoutes.COUNTRY.url}`);
      if (res.data.statusCode === 200) {
        setallcountries(res.data.data);
        setcountries(
          res.data.data?.map((ele) => ({
            value: ele?.countryId,
            label: ele?.name,
          }))
        );
        setconstCountries(
          res.data.data?.map((ele) => ({
            value: ele?.countryId,
            label: ele?.name,
          }))
        );
        res.data.data?.forEach((ele) => {
          if(ele?.countryId == sessionStorage.getItem("storeCouId")){
            
            setstaff({
              ...staff,businessPhoneCode : ele?.phoneCode
            })
          }
        
        })
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [form] = Form.useForm();
  const handleSearch = (value, constStateVariables, setState) => {
    //search fn for select

    if (value?.length > 0 && value !== null) {
      const filteredData = constStateVariables.filter((state) =>
        state.label.toLowerCase().includes(value.toLowerCase())
      );
      return setState(filteredData);
    } else {
      return setState(constStateVariables);
    }
  };

  const handleGetStates = async (id) => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.STATE.service}${ApiRoutes.STATE.url}/${id}`);
      if (res.data.statusCode === 200) {
        setstates([
          { value: "default", label: "Please select a state", disabled: true },
          ...res.data.data?.map((ele) => ({
            label: ele?.stateName,
            value: ele?.stateId,
          })),
        ]);
        setConstStates([
          { value: "default", label: "Please select a state", disabled: true },
          ...res.data.data?.map((ele) => ({
            label: ele?.stateName,
            value: ele?.stateId,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCities = async (id) => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.CITY.service}${ApiRoutes.CITY.url}/${id}`);

      if (res.data.data.length > 0) {
        setcities([
          { value: "", label: "Please select a city", disabled: true },
          ...res.data.data?.map((ele) => ({
            value: ele?.cityId,
            label: ele?.cityName,
          })),
        ]);
        setConstCities([
          ...res.data.data?.map((ele) => ({
            value: ele?.cityId,
            label: ele?.cityName,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetLocalAreas = async (id) => 
  {
    try {
      let res = await AxiosService.get(`${ApiRoutes.LOCALAREA.service}${ApiRoutes.LOCALAREA.url}/${id}`);

      if (res.data.data.length > 0) 
      {
        setlocalareas([
          { value: "", label: "Please select a Local Area", disabled: true },
          ...res.data.data?.map((ele) => ({
            value: ele?.id,
            label: ele?.areaName,
          })),
        ]);
        setConstLocalAreas([
          ...res.data.data?.map((ele) => ({
            value: ele?.id,
            label: ele?.areaName,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCountryChange = (value) => {
    handleSearch(null, constCountries, setcountries);
    setstaff({
      ...staff,
      businessPhoneCode:
        "+" + (allcountries?.find((ele) => ele.countryId === value)).phoneCode,
      country: value,
      state: null,
      city: null,
      localArea: null,
    });
    form.setFieldsValue({
      businessPhoneCode:
        "+" + (allcountries?.find((ele) => ele.countryId === value)).phoneCode,
      stateName: "Please select a state",
      cityName: "Please select a city",
    });

    // form.setFields([
    //   {
    //     name: "stateName",
    //     value: "Please select a state",
    //   },
    // ]);
    // form.setFields([
    //   {
    //     name: "cityName",
    //     value: "Please select a city",
    //   },
    // ]);

    setSelectedCountry(value);

    setstates([]);
    handleGetStates(value);
  };

  useEffect(() => {
    handleGetCountries();
  }, []);

  const onFinish = (values) => {
    handleEditProfile(values);
  };

  const getStaffById = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.STAFF.service}${ApiRoutes.STAFF.url}/${staffId}`);
      if (res.data.statusCode === 200) {
        setstaff(res.data.employeeDetails);
        setroleList(res.data.roleList);
        setempRoles(res.data.empRoles);
        if (res.data.employeeDetails?.country) {
          handleGetStates(res.data.employeeDetails?.country);
        }
        if (res.data.employeeDetails?.state) {
          handleGetCities(res.data.employeeDetails?.state);
          handleGetLocalAreas(res.data.employeeDetails?.city);
        }

        res.data.roleList?.forEach((role) => {
          if (role?.Description === "Make A Sale") {
            setshowDis(
              res.data.empRoles.some((roleId) => roleId === role?.roleId)
            );
          }
        });
        setisloader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaffById();
  }, []);

  // const handleSearchCities = (value) =>{
  //   if(value?.length > 0){
  //     const filteredData = constCities.filter((city) =>
  //     city.label.toLowerCase().includes(value.toLowerCase())

  //   );
  //   return  setcities(filteredData);
  //   }else{
  //     return setcities(constCities);
  //   }
  // }

  // const handleSearchCountries = (value) =>{
  //   if(value?.length > 0){
  //     const filteredData = constCountries.filter((city) =>
  //     city.label.toLowerCase().includes(value.toLowerCase())

  //   );
  //   return  setcountries(filteredData);
  //   }else{
  //     return setcountries(constCountries);
  //   }
  // }
  const handleDefaultChecked = (id, text) => {
    return empRoles.some((roleId) => roleId === id);
  };

  useEffect(() => {
    if (empRoles.length) {
      handleGetDesignation();
    }
  }, [empRoles, staffId]);

  return (
    <>
      {isloader ? (
        <div className="adminContentSection">
          <SupportLoader />
        </div>
      ) : (
        <div className="adminContentSection">
          <Row gutter={8}>
            <Col span={24}>
              <div className="displayFlex mt10 mb-1 spaceBetween">
                <Button
                  type="warning"
                  className="mr-3 btn--large d-flex align-items-center"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ArrowLeftOutlined /> Back
                </Button>
              </div>
            </Col>
          </Row>
          <Row gutter={8}>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              className="editProfileForm"
              style={{ width: "100%" }}
              initialValues={{
                firstName: staff?.firstName || "",
                lastName: staff?.lastName || "",
                email: staff?.email || "",
                businessPhone: staff?.businessPhone || "",
                userName: staff?.userName || "",
                state: staff?.state || "",
                city: staff?.city || "",
                localArea: staff?.localArea || "",
                country: staff?.country || "",
                stateName: staff?.stateName || "",
                cityName: staff?.cityName || "",
                countryName: staff?.countryName || "",
                zip: (staff?.zip && staff?.zip !== "null" && staff?.zip) || "",
                addressLine1: staff?.addressLine1 || "",
                isAllowDiscount: staff?.isAllowDiscount || "",
                saleItemPermission: staff?.saleItemPermission || "",
              }}
              onFinish={onFinish}
            >
              <Col span={16} offset={4}>
                <div className="profileDetailsRight bg-white mb-1">
                  <Row gutter={18}>
                    <Col span={24}>
                      <h4 className="mb-1">PERSONAL INFORMATION</h4>
                    </Col>
                    {/* <Col span={24} className='text-center'>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{
                            width: '100%',
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Col> */}
                    <Col span={12}>
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        type="text"
                        rules={[
                          {
                            required: true,
                            message: "Please input your last name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Email" name="email">
                        <Input
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              email: value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Mobile Phone" name="businessPhone">
                        <Input
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              businessPhone: value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Username" name="userName">
                        <Input
                          disabled
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              userName: value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="profileDetailsRight bg-white mb-1">
                  <Row gutter={18}>
                    <Col span={24}>
                      <h4 className="mb-1">ADDRESS INFORMATION</h4>
                    </Col>
                    {/* <Col span={12}>
                      <Form.Item label="Country" name="countryName">
                        <Select
                          style={{ width: "100%" }}
                          options={countries}
                          onChange={onCountryChange}
                          onSearch={(value) => {
                            handleSearch(value, constCountries, setcountries);
                          }}
                          showSearch={true}
                          filterOption={false}
                        />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item label="State" name="stateName">
                        <Select
                          style={{ width: "100%" }}
                          selected={selectedCountry === null}
                          // labelInValue
                          onSearch={(value) => {
                            handleSearch(value, constStates, setstates);
                          }}
                          showSearch={true}
                          filterOption={false}
                          options={states}
                          onChange={(value) => {
                            handleSearch(null, constStates, setstates); //to reset the search option to inital state
                            setstaff({
                              ...staff,
                              state: value,
                            });
                            handleGetCities(value);

                            setstaff({
                              //reset city as state changes
                              ...staff,
                              city: null,
                            });
                            form.setFieldsValue({
                              cityName: "Please select a city",
                            });
                          }}
                          value={selectedCountry ? "" : staff.state || ""}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="City" name="cityName">
                        <Select
                          style={{ width: "100%" }}
                          options={cities}
                          onSearch={(value) => {
                            handleSearch(value, constCities, setcities);
                          }}
                          showSearch={true}
                          filterOption={false}
                          onChange={(value) => {
                            handleSearch(null, constCities, setcities);
                            setstaff({
                              ...staff,
                              city: value,
                            });
                            handleGetLocalAreas(value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {sessionStorage.getItem("storeCouId") == 236 && (
                      <Col span={12}>
                        <Form.Item label="Local Area" name="localArea">
                          <Select
                            style={{ width: "100%" }}
                            options={localAreas}
                            onSearch={(value) => {
                              handleSearch(value, constLocalArea, setlocalareas);
                            }}
                            showSearch={true}
                            filterOption={false}
                            onChange={(value) => {
                              handleSearch(null, constLocalArea, setlocalareas);
                              setstaff({
                                ...staff,
                                localArea: value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={12}>
                      <Form.Item label="Zipcode" name="zip">
                        <Input
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              zip: value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Street Address" name="addressLine1">
                        <Input
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              addressLine1: value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="profileDetailsRight bg-white mb-1">
                  <Row gutter={18}>
                    <Col span={24}>
                      <h4 className="mb-1">ROLES</h4>
                    </Col>
                    <Col span={12}>
                      <Input
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={18}>
                    <Col span={24} className="mb-1 custom--checkbox">
                      {roleList.length > 0 ? (
                        roleList?.map((role, i) => {
                          return (
                            <Checkbox
                              name={role?.Description}
                              // value={role?.roleId}
                              defaultChecked={handleDefaultChecked(
                                role?.roleId,
                                role?.Description
                              )}
                              onChange={(e) => {
                                const roleId = role?.roleId;
                                if (e.target.checked) {
                                  setempRoles([...empRoles, role?.roleId]);
                                  if (role?.Description === "Make A Sale") {
                                    setshowDis(true);
                                  }
                                } else {
                                  setempRoles(
                                    empRoles.filter((id) => id !== roleId)
                                  );
                                  if (role?.Description === "Make A Sale") {
                                    setshowDis(false);
                                    setstaff({
                                      ...staff,
                                      saleItemPermission: 0,
                                      isAllowDiscount: 0,
                                    });
                                  }
                                }
                              }}
                              key={i}
                            >
                              {role?.Description}
                            </Checkbox>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Col>

                    <br />
                    <br />
                    {showDis ? (
                      <>
                        <Col span={24}>
                          <h4 className="mb-1">DISCOUNT PERMISSION</h4>
                        </Col>
                        <Col span={24} className="mb-1 custom--checkbox">
                          <Checkbox
                            name="isAllowDiscount"
                            defaultChecked={staff?.isAllowDiscount === 1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setstaff({
                                  ...staff,
                                  isAllowDiscount: 1,
                                });
                              } else {
                                setstaff({
                                  ...staff,
                                  isAllowDiscount: 0,
                                });
                              }
                            }}
                          >
                            Cart Discount
                          </Checkbox>

                          <Checkbox
                            name="saleItemPermission"
                            defaultChecked={staff?.saleItemPermission === 1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setstaff({
                                  ...staff,
                                  saleItemPermission: 1,
                                });
                              } else {
                                setstaff({
                                  ...staff,
                                  saleItemPermission: 0,
                                });
                              }
                            }}
                          >
                            Sale item Permission
                          </Checkbox>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </div>
                <div className="text-right mb-1">
                  <Button
                    type="primary"
                    className="mr-3 btn--large"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="warning"
                    htmlType="submit"
                    className="btn--large"
                    onClick={() => setsubloader(true)}
                  >
                    {subloader ? <SupportLoader /> : "Save"}
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
        </div>
      )}
    </>
  );
};
export default MerchantEditProfile;
