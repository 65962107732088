import { EnvironmentOutlined, FlagOutlined, LoginOutlined, MailOutlined, PhoneOutlined, ShareAltOutlined, ShopOutlined, UserOutlined, IdcardOutlined, PictureOutlined } from '@ant-design/icons';
import { Button, Col, Row, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';
import AxiosService from '../../../../utils/Config/ApiService';

const ViewLead = () => {

  const history = useHistory();
  const params = useParams();

  const [messageApi, contextHolder] = message.useMessage();

  const [leadDetail, setLeadDetail] = useState(null)

  const getLeadDetails = async () => {
    try{
      const response = await AxiosService.get(`${ApiRoutes.LEAD_DETAIL.service}${ApiRoutes.LEAD_DETAIL.url}${params.id}`);
      if(response.status === 200){
        setLeadDetail(response.data.leadDetails)
      }
    }catch(error){
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.error ?? error.response.data.message
      })
    }
  }

  useEffect(()=>{
    getLeadDetails();
  },[])

  return (
    <>
      {contextHolder}
      <div className="text-right mb-1">
        <Button
          type="warning"
          className="btn--large"
          onClick={()=>history.goBack()}
        >
          Back
        </Button>
      </div>
      <Row gutter={8} className="mb-05">
        <Col span={16}>
          <div className="profileDetailsRight bg-white">
            <Row gutter={8}>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <ShopOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Merchant Name</p>
                    <p>{leadDetail?.leadName}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <ShopOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Lead Address</p>
                    <p>{leadDetail?.streetAddress}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <FlagOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">
                      Country / State / City Name
                    </p>
                    <p>
                      {leadDetail?.countryName} / {leadDetail?.stateName} / {leadDetail?.cityName}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <EnvironmentOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Zipcode</p>
                    <p>{leadDetail?.zipcode}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <MailOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Email</p>
                    <p>{leadDetail?.email}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <UserOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Campaign Name</p>
                    <p>
                      {leadDetail?.campaignName}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <UserOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Distributor Name</p>
                    <p>
                      {leadDetail?.distributorName}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <PhoneOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Phone</p>
                    <p>
                      {leadDetail?.phoneNumber}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <UserOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Agent Name</p>
                    <p>
                      {leadDetail?.agentName}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <LoginOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Status</p>
                    <p>{leadDetail?.status === 1 ? "Hot" : "Lost"}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={8}>
          <div className="profileDetailsLeft bg-white" style={{ display: 'flex', justifyContent: "center", flexDirection: "column"}}>
            <div className="displayFlex">
                <div className="mr-3">
                    <PictureOutlined />
                </div>
                <div>
                    <p className="fw-700 mb-0">Lead Location</p>
                </div>
            </div>
            {
              leadDetail?.imageName ? 
              <img src={leadDetail?.imageName} alt="Location"/> :
              <img src={"https://community.softr.io/uploads/db9110/original/2X/7/74e6e7e382d0ff5d7773ca9a87e6f6f8817a68a6.jpeg"} alt="No Location"/>
            }
          </div>
        </Col>
      </Row>      
    </>
  )
}

export default ViewLead