import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { messages } from "../../../utils/Common/Messages";
import zh_CN from 'antd/es/date-picker/locale/zh_CN'
import fr_FR from 'antd/es/date-picker/locale/fr_FR'
import en_US from 'antd/es/date-picker/locale/en_US'
import pt_PT from 'antd/es/date-picker/locale/pt_PT'
import es_ES from 'antd/es/date-picker/locale/es_ES'
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const Rangepicker = (props) => {
  const {filterdata, startDate, endDate, lan} = props;
  const onChange = (dates, dateStrings) => {
    filterdata(dateStrings[0], dateStrings[1]);
  };

  let language = {
    ZH:zh_CN,
    GU:en_US,
    PT:pt_PT,
    EN:en_US,
    FR:fr_FR,
    ES:es_ES
  }

  return (
    <RangePicker
      format={dateFormat}
      allowClear={false}
      defaultValue={[moment(startDate), moment(endDate)]}
      ranges={{
        [messages.Today[lan]]: [moment(), moment()],
        [messages.Yesterday[lan]]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        [messages.thisWeek[lan]]: [moment().startOf("week"), moment().endOf("week")],
        [messages.lastWeek[lan]]: [
          moment().subtract(1, "week").startOf("week"),
          moment().subtract(1, "week").endOf("week"),
        ],
        [messages.thisMonth[lan]]: [moment().startOf("month"), moment().endOf("month")],
        [messages.lastMonth[lan]]: [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
        [messages.thisYear[lan]]: [moment().startOf("year"), moment().endOf("year")],
        [messages.lastYear[lan]]: [
          moment().subtract(1, "year").startOf("year"),
          moment().subtract(1, "year").endOf("year"),
        ],
      }}
      onChange={onChange}
      readOnly
      locale={language[lan]}
    />
  );
};
export default Rangepicker;
