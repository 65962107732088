import React, { useState, useEffect } from "react";
import {
  Select,
  Divider,
  Modal,
  Form,
  Input,
  TimePicker,
  Row,
  Col,
  message,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  FieldTimeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  ApiHelper,
  message as cMessage,
  getUserData,
} from "../../../utils/Helpers";
import { messages } from "../../../utils/Common/Messages";
import { ApiRoutes } from "../../../utils/Config/ApiRoutes";
const { Option } = Select;
const { confirm } = Modal;

/*----------------All Message--------------
message.success({
  content: 'This is a message of success',
  duration: 2
});
message.error({
  content: 'This is an error message',
  duration: 2
});
message.warning ({
  content: 'This is a warning message',
  duration: 2
});
message.info ({
  content: 'This is a normal message',
  duration: 2
});
------------------------------------------*/

const SelectEmployePopoup = ({
  visibleEmploye,
  onCancel,
}) => {

  const lan = getUserData().language ?? "EN";
  const format = "HH:mm A";
  return (
    <Modal
      title={
        <div style={{ textAlign: 'center' }}>
          Please select an employee first.
        </div>
      }
      visible={visibleEmploye}
      onOk={onCancel}
      onCancel={onCancel}
    >
    </Modal>
  );
};

const ShiftDetailsForm = ({
  visible,
  onCreate,
  timeZoneData,
  onCancel,
  confirmLoading,
  setConfirmLoading,
}) => {
  const [form] = Form.useForm();
  const lan = getUserData().language ?? "EN";
  // const [spinLoading, setSpinLoading] = React.useState(false);
  const format = "HH:mm A";
  return (
    <Modal
      visible={visible}
      centered
      title={messages.addReportingHours[lan]}
      okText={messages.save[lan]}
      cancelText={messages.cancel[lan]}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {/* <Spin spinning={spinLoading} delay={500}> </Spin>*/}
      <Form
        form={form}
        layout="vertical"
        name="reporting_hours_form"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="shiftName"
          label={messages.shiftName[lan]}
          rules={[
            {
              required: true,
              message: messages.shiftNameRequired[lan],
            },
          ]}
        >
          <Input placeholder={messages.shiftNamePlaceholder[lan]} />
        </Form.Item>
        <Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="startTime"
                label={messages.startTime[lan]}
                rules={[
                  { required: true, message: messages.startTimeRequired[lan] },
                ]}
                style={{
                  display: "inline-block",
                  width: "100%",
                  marginBottom: "0px",
                }}
              >
                <TimePicker
                  format={format}
                  placeholder={messages.selecttime[lan]}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="endTime"
                label={messages.endTime[lan]}
                rules={[
                  { required: true, message: messages.endTimeMessage[lan] },
                ]}
                style={{
                  display: "inline-block",
                  width: "100%",
                  marginBottom: "0px",
                }}
              >
                <TimePicker
                  placeholder={messages.selecttime[lan]}
                  format={format}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="timeZone"
          label={messages.timezone[lan]}
          rules={[
            {
              required: true,
              message: messages.timezoneRequired[lan],
            },
          ]}
        >
          <Select
            placeholder={messages.timezonePlaceholder[lan]}
            className="locationSelect"
            dropdownClassName="locationDropdown"
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {timeZoneData &&
              timeZoneData.map((zone, key) => (
                <Option value={zone.timezoneId} key={key}>
                  {zone.timezone}
                </Option>
              ))}
            ;
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Allday = (props) => {
  const [visible, setVisible] = useState(false);
  const [visibleEmploye, setVisibleEmploye] = useState(false);
  const [shiftList, setShiftList] = useState(props.shiftTime);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const lan = getUserData().language ?? "EN";
  useEffect(() => {
    setShiftList(props.shiftTime);
  }, [props.shiftTime]);

  const fetchTimeZone = async () => {
    if (props.employeeId && props.employeeId != '') {
      if (props.timeZone.length === 0) {
        const response = await new ApiHelper().FetchFromServer(
          ApiRoutes.LISTTIMEZONE.service,
          ApiRoutes.LISTTIMEZONE.url,
          ApiRoutes.LISTTIMEZONE.method,
          ApiRoutes.LISTTIMEZONE.authenticate
        );
        if (response) {
          props.setTimeZone(response.data.data);
          setVisible(true);
          setConfirmLoading(false);
        }
      } else {
        setVisible(true);
      }
    } else {
      setVisibleEmploye(true);
    }
  };
  const selectOnChange = (value) => {
    props.filterdata("", "", value);
  };
  const onCreate = async (values) => {
    values.endTime = values.endTime.format("HH:mm:ss");
    values.startTime = values.startTime.format("HH:mm:ss");
    values.organizationId = props.organizationId;
    values.employeeId = props.employeeId;
    setConfirmLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADDTIMEZONE.service,
      ApiRoutes.ADDTIMEZONE.url,
      ApiRoutes.ADDTIMEZONE.method,
      ApiRoutes.ADDTIMEZONE.authenticate,
      undefined,
      values
    );
    if (response) {
      if (response.code === 200) {
        message.success({
          content: cMessage.reportHourSuccess,
          duration: 2,
        });
        setShiftList(response.data.shiftData);
        props.ShiftTimeUpdate(response.data.shiftData);
      } else {
        message.error({
          content: cMessage.reportHourError,
          duration: 2,
        });
      }
      setConfirmLoading(false);
      setVisible(false);
    }
  };

  const deleteTimezone = async (id) => {
    const responseDelete = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_TIMEZONE.service,
      ApiRoutes.DELETE_TIMEZONE.url.replace(":id", id),
      ApiRoutes.DELETE_TIMEZONE.method,
      ApiRoutes.DELETE_TIMEZONE.authenticate
    );
    if (responseDelete) {
      let shiftListArr = [];
      for (let x in shiftList) {
        if (id !== shiftList[x].shiftHourId) {
          shiftListArr.push(shiftList[x]);
        }
      }
      props.filterdata("", "", "");
      setShiftList(shiftListArr);
      props.ShiftTimeUpdate(shiftListArr);
    }
  };
  function showDeleteConfirm(id) {
    confirm({
      title: messages.deleteReportingHoursMessage[lan],
      icon: <ExclamationCircleOutlined />,
      okText: messages.Yes[lan],
      okType: "danger",
      cancelText: messages.No[lan],
      onOk() {
        deleteTimezone(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  return (
    <>
      <SelectEmployePopoup
        visibleEmploye={visibleEmploye}
        onCancel={() => {
          setVisibleEmploye(false);
        }}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
      />
      <ShiftDetailsForm
        visible={visible}
        onCreate={onCreate}
        timeZoneData={props.timeZone}
        onCancel={() => {
          setVisible(false);
        }}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
      />
      <Select
        defaultValue={props.selectvalue}
        value={props.selectvalue}
        className="locationSelect"
        dropdownClassName="locationDropdown"
        style={{ width: 175 }}
        onChange={selectOnChange}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div className="addReportingSec">
              <span
                className="cursor-pointer"
                type="text"
                onClick={() => {
                  fetchTimeZone();
                }}
                title={messages.addReportingHours[lan]}
              >
                <PlusOutlined />
                {messages.addReportingHours[lan]}
              </span>
            </div>
          </div>
        )}
      >
        <Option value="">
          <FieldTimeOutlined /> {messages.allday[lan]}
        </Option>
        {shiftList &&
          shiftList.map((shift, key) => (
            <Option value={shift.shiftHourId} key={key}>
              <FieldTimeOutlined /> {shift.shiftName}{" "}
              <span
                className="reporting-delete-icon"
                onClick={() => showDeleteConfirm(shift.shiftHourId)}
              >
                <Tooltip placement="right" title={messages.Delete[lan]}>
                  <DeleteOutlined />
                </Tooltip>
              </span>
            </Option>
          ))}
      </Select>
    </>
  );
};
export default Allday;
