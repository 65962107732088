import React from "react";
import { Table } from "antd";
import {
  DollarOutlined,
  CreditCardOutlined,
  GiftOutlined
} from "@ant-design/icons";
import { messages } from "../../../../utils/Common/Messages";

function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const  voidData = (data)=>{
    let ebt_void = 0 
    let card_void = 0

    data.forEach((e)=>{
      if(e.voidType==='EBT Void')
        ebt_void = e.voidAmount
      else if(e.voidType==='Card Void')
        card_void = e.voidAmount
    })

    return {
      ebt_void,
      card_void
    }
}

const Paymentcollected = (props) => {
  const { interval, serachDataPayment, dataSetVoidSummaryReport,lan, currencySign } = props;
  const {ebt_void,card_void} = voidData(dataSetVoidSummaryReport)

  const columns = [
    {
      title: messages.sales[lan],
      dataIndex: "label",
      key: "label",
      fixed: "left",
      width: 170,
      render: (label) => (
        <>
          {label.map((tag) => {
            let icon = tag;
            if (tag.toLowerCase() === "cash") {
              icon = <DollarOutlined className="fs-15 icon" />;
            } else if (tag.toLowerCase() === "card") {
              icon = <CreditCardOutlined className="fs-15 icon" />;
              // } else if (tag.toLowerCase() === "wallet") {
              //   icon = <WalletOutlined className="fs-15 icon" />;
            } else if (
              tag.toLowerCase() === "other" ||
              tag.toLowerCase() === "ebt" ||
              tag.toLowerCase() === "custom payment" || 
              tag.toLowerCase() === "invoice payment"
            ) {
              icon = <GiftOutlined className="fs-15 icon" />;
            } else icon = "";

            return (
              <div key={tag}>
                {icon} <span>{jsUcfirst(tag)}</span>
              </div>
            );
          })}
        </>
      )
    }
  ];
  const data = [
    {
      key: "totalcollected",
      label: [messages.totalCollected[lan]]
    }
  ];
  let cash = {
    key: "cash",
    label: [messages.cash[lan]]
  };
  let card = {
    key: "card",
    label: [messages.card[lan]]
  };
  let EBT = {
    key: "EBT",
    label: [messages.EBT[lan]]
  };
  let customPayment = {
    key: "custom_payment",
    label: [messages.customPayment[lan]]
  };
  // let customerInvoice = {
  //   key: "CustomerInvoice",
  //   label: [messages.customerInvoice[lan]]
  // };
  let other = {
    key: "other",
    label: [messages.other[lan]]
  };

  let children = [];
  let keyChildren = [];
  if (serachDataPayment && serachDataPayment.length > 0) {
    for (let i = 0; i < serachDataPayment.length; i++) {
      if (
        serachDataPayment[i].otherDistribution !== undefined &&
        serachDataPayment[i].otherDistribution !== null &&
        serachDataPayment[i].otherDistribution !== ""
      ) {
        let otherTemp = JSON.parse(serachDataPayment[i].otherDistribution);

        otherTemp.forEach((value) => {
          if (keyChildren.indexOf(value.key) < 0) {
            keyChildren.push(value.key);
            children.push({
              key: value.key,
              label: [value.key]
            });
          }
        });
      }
    }
    for (let i = 0; i < serachDataPayment.length; i++) {
      columns.push({
        title:
          serachDataPayment[i].interval_start !==
          serachDataPayment[i].interval_end
            ? serachDataPayment[i].interval_start +
              "-" +
              serachDataPayment[i].interval_end
            : serachDataPayment[i].interval_start,
        dataIndex: `dayfilterData${i + 1}`,
        key: i + 1,
        width: 150,
        align: "right"
      });
      let total = parseFloat(
        parseFloat(serachDataPayment[i].cash_amt) +
          parseFloat(serachDataPayment[i].card_amt) +
          parseFloat(serachDataPayment[i].other_amt) +
          parseFloat(serachDataPayment[i].custom_amt) +
          // parseFloat(serachDataPayment[i].CustomerInvoice) +
          parseFloat(serachDataPayment[i].ebt_amt)
      ).toFixed(2);
      data[0][`dayfilterData${i + 1}`] =
        (total-card_void-ebt_void) >= 0
          ? currencySign + Number(total-card_void-ebt_void).toFixed(2)
          : "-" + currencySign + Math.abs(total-card_void-ebt_void).toFixed(2);
      cash[`dayfilterData${i + 1}`] =
        serachDataPayment[i].cash_amt >= 0
          ? currencySign + Number(serachDataPayment[i].cash_amt).toFixed(2)
          : "-" + currencySign + Math.abs(serachDataPayment[i].cash_amt).toFixed(2);
      card[`dayfilterData${i + 1}`] = 
      serachDataPayment[i].card_amt - card_void >= 0 ? currencySign + Number(serachDataPayment[i].card_amt - card_void).toFixed(2)
          : "-" + currencySign + Math.abs(serachDataPayment[i].card_amt - card_void).toFixed(2);

      customPayment[`dayfilterData${i + 1}`] =
        serachDataPayment[i].custom_amt >= 0
          ? currencySign + Number(serachDataPayment[i].custom_amt).toFixed(2)
          : "-" + currencySign + Math.abs(serachDataPayment[i].custom_amt).toFixed(2);

      EBT[`dayfilterData${i + 1}`] =
        serachDataPayment[i].ebt_amt - ebt_void >= 0
          ? currencySign + Number(serachDataPayment[i].ebt_amt - ebt_void).toFixed(2)
          : "-" + currencySign + Math.abs(serachDataPayment[i].ebt_amt - ebt_void).toFixed(2);
      
      // customerInvoice[`dayfilterData${i + 1}`] =
      //     serachDataPayment[i].CustomerInvoice >= 0
      //       ? currencySign + serachDataPayment[i].CustomerInvoice
      //       : "-" + currencySign + Math.abs(serachDataPayment[i].CustomerInvoice);

      other[`dayfilterData${i + 1}`] =
        serachDataPayment[i].other_amt >= 0
          ? currencySign + Number(serachDataPayment[i].other_amt).toFixed(2)
          : "-" + currencySign + Math.abs(serachDataPayment[i].other_amt).toFixed(2);
      if (children.length > 0) {
        if (
          serachDataPayment[i].otherDistribution !== undefined &&
          serachDataPayment[i].otherDistribution !== null &&
          serachDataPayment[i].otherDistribution !== ""
        ) {
          let otherDistribution = JSON.parse(
            serachDataPayment[i].otherDistribution
          );
          children.forEach((value, key) => {
            children[key][`dayfilterData${i + 1}`] =
              currencySign +
              otherDistribution
                .map((item) => (item.key === value.key ? item.amt : 0))
                .reduce((prev, next) => prev + next);
          });
        } else {
          children.forEach((value, key) => {
            children[key][`dayfilterData${i + 1}`] = currencySign + "0";
          });
        }
      }

      // other[`dayfilterData${i + 1}`] =
      //   serachDataPayment[i].wallet_amt >= 0
      //     ? currencySign + serachDataPayment[i].wallet_amt
      //     : "-" + currencySign + Math.abs(serachDataPayment[i].wallet_amt);
    }
  }
  if (children.length > 0) {
    other.children = children;
  }
  data.push(cash);
  data.push(card);
  if (currencySign === "$") data.push(EBT);
  data.push(customPayment);
  // data.push(customerInvoice);
  data.push(other);
  return (
    <Table
      locale={{ emptyText: messages.noData[lan] }}
      columns={columns}
      dataSource={data}
      scroll={interval === "summary" || columns.length < 3 ? "" : { x: 1500 }}
      pagination={false}
      className="paymentcollectedTable"
    />
  );
};
export default Paymentcollected;
